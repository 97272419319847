import Vue from "vue";
import Vuex from "vuex";
import blogModule from "./modules/blogModule";
import btnDisabledModule from "./modules/btnDisabledModule";
import cdeModule from "./modules/cdeModule";
import cdesModule from "./modules/cdesModule";
import contactModule from "./modules/contactModule";
import courseCharacteristicsModule from "./modules/courseCharacteristicsModule";
import courseDescriptionModule from "./modules/courseDescriptionModule";
import courseDescriptionTabsModule from "./modules/courseDescriptionTabsModule";
import coursesWrapperModule from "./modules/coursesWrapperModule";
import descriptionModule from "./modules/descriptionModule";
import headerModule from "./modules/headerModule";
import headerPhotoModule from "./modules/headerPhotoModule";
import indexModule from "./modules/indexModule";
import mapModule from "./modules/mapModule";
import privacyModule from "./modules/privacyModule";
import reloadModule from "./modules/reloadModule";
import scholarshipsModule from "./modules/scholarshipsModule";
import scholarsModule from "./modules/scholarsModule";
import vocacionalModule from "./modules/vocacionalModule";
import sideModule from "./modules/sideModule";
import tokenModule from "./modules/tokenModule";
import weAreModule from "./modules/weAreModule";
import horarioModule from "./modules/horarioModule";
import telfijoModule from "./modules/telfijoModule";
import entrecallesModule from "./modules/entrecallesModule";
import contactService from "./modules/contactService";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    blogModule,
    btnDisabledModule,
    cdeModule,
    cdesModule,
    contactModule,
    courseCharacteristicsModule,
    courseDescriptionModule,
    courseDescriptionTabsModule,
    coursesWrapperModule,
    descriptionModule,
    headerModule,
    headerPhotoModule,
    indexModule,
    mapModule,
    privacyModule,
    reloadModule,
    scholarshipsModule,
    scholarsModule,
    vocacionalModule,
    sideModule,
    tokenModule,
    weAreModule,
    horarioModule,
    telfijoModule,
    entrecallesModule,
    contactService,
  },
});
