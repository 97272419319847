<template>
  <div id="scholars">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />

      <div class="mx-auto">
        <div class="degreesContainer">
          <p class="text-h4 text-center font-weight-bold pt-8">Bachilleratos</p>
        </div>
        <v-container
          class="d-none d-md-block mx-auto"
          style="max-width: 950px; margin-top: -200px"
        >
          <vueper-slides
            autoplay
            class="no-shadow"
            :visible-slides="3"
            :slide-ratio="1 / 2"
            :gap="3"
            :dragging-distance="70"
            duration="4000"
          >
            <vueper-slide
              v-for="(slide, i) in degrees"
              :key="i"
              :image="slide.src"
              class="slide-card text-center"
              style="border-radius: 10px"
            >
              <template #content>
                <div class="content_card_slider">
                  <span
                    class="text-h6 font-weight-medium text-center mx-auto"
                    style="color: #fff"
                  >
                    {{ slide.text }}
                  </span>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </v-container>
        <v-container
          class="mx-auto d-md-none"
          style="max-width: 950px; margin-top: -200px"
        >
          <vueper-slides
            autoplay
            class="no-shadow"
            fade
            duration="4000"
            :slide-ratio="1 / 2"
            fixed-height="463px"
            bullets-outside
            :arrows="false"
            :pause-on-hover="false"
            :pause-on-touch="false"
          >
            <vueper-slide
              v-for="(slide, i) in degrees"
              :key="i"
              :image="slide.src"
              class="slide-card text-center mx-auto"
              style="border-radius: 10px"
            >
              <template #content>
                <div class="content_card_slider">
                  <span
                    class="text-h6 font-weight-medium text-center mx-auto"
                    style="color: #fff"
                  >
                    {{ slide.text }}
                  </span>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </v-container>
      </div>

      <why-ende />
      <our-plantel />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/FormHeader.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
// import contactForm from "../components/ContactForm.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import WhyEnde from "@/components/stateless/WhyEnde.vue";
import OurPlantel from "@/components/stateless/OurPlantel.vue";
import axios from "axios";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

var setLocationDecorationHeight;
var setLocationContainerPadding;
export default {
  name: "ScholarsView",

  title() {
    return `AHJ ENDE Asuntos Escolares`;
  },

  data: () => ({
    position: 0,
    loading: true,
    degrees: [
      {
        src: "/img/bachilleratosView/emprende.webp",
        title: "Prepa EMPR-ENDE",
        text: "Prepa EMPR-ENDE",
      },
      {
        src: "/img/bachilleratosView/negocios.webp",
        title: "Bachillerato en Negocios",
        text: "Bachillerato en Negocios",
      },
      {
        src: "/img/bachilleratosView/administracion.webp",
        title: "Bachillerato con Diplomado <br/> en Administración",
        text: "Bachillerato con Diplomado en Administración",
      },
      {
        src: "/img/bachilleratosView/ingles.webp",
        title: "Inglés en Negocios",
        text: "Inglés en Negocios",
      },
    ],
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
    WhyEnde,
    OurPlantel,
    VueperSlides,
    VueperSlide,
  },

  created() {
    this.getSearchElements();
    this.header[["firstText"]] = "Conoce nuestros";
    this.header[["secondText"]] = "bachilleratos";
    this.headerPhoto = "/img/bachilleratosView/Bachillerato_recortado.jpg";
    this.description =
      "Nuestros programas de estudios están diseñados para brindarte la mejor calidad de conocimiento para tu aprovechamiento y desarrollo, contamos con modalidades presenciales y en línea, consulta con tu asesor inicios de ciclo en cada modalidad.";
    this.scholars = true;
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
  },

  destroyed() {
    this.scholars = false;
  },
  beforeMount() {
    this.$store.dispatch("contactService/SET_ITEMS_FORM", [
      "EMPR-ENDE",
      "Negocios",
      "Diplomado en administración",
      "Inglés en Negocios",
    ]);
    this.$store.dispatch(
      "contactService/SET_QUESTION",
      "¿Qué bachillerato te interesa?"
    );
  },
  methods: {
    prevScholar() {
      this.position -= 1;
      this.getSearchElements();
    },

    nextScholar() {
      this.position += 1;
      this.getSearchElements();
    },

    getSearchElements() {
      var end = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      axios
        .get(end[this.position], {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[[wrapperPos[this.position]]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            setLocationDecorationHeight = setInterval(
              this.setLocationDecorationHeight,
              100
            );
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
          }, 100);
        });
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("scholarsDecoration");
      var container = window.document.getElementById("scholarsContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var scholarsContainer = window.document.getElementById(
        "scholarsElementComponent"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      scholarsContainer.style.marginLeft = `${paddingElement}px`;
      scholarsContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },

    scholars: {
      get() {
        return this.$store.getFters["scholarsModule/isScholarsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarsModule/isScholarsActive"] !== newState
        ) {
          this.$store.dispatch("scholarsModule/toogleScholarsState", newState);
        }
      },
    },
  },
};
</script>
