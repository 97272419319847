<template>
  <div id="index">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />

      <div class="mx-auto">
        <div class="degreesContainer">
          <p class="text-h4 text-center font-weight-bold pt-8">
            Oferta Educativa
          </p>
          <p
            class="text-caption text-md-body-1 text-center mx-auto my-2 my-md-4 font-weight-regular customWidth"
          >
            Nuestros programas de estudios están diseñados para brindarte la
            mejor calidad de conocimiento para tu aprovechamiento y desarrollo,
            contamos con modalidades presenciales y en línea, consulta con tu
            asesor inicios de ciclo en cada modalidad.
          </p>
        </div>

        <v-container
          style="margin-top: -120px; max-width: 1300px"
          fluid
          class="mx-auto d-none d-md-flex"
        >
          <v-row justify="center" align="center" class="mx-auto">
            <v-col
              cols="11"
              sm="6"
              md="3"
              v-for="(scholar, i) in degrees"
              :key="i"
            >
              <v-card
                width="350"
                height="350"
                color="#00add1"
                class="d-flex flex-column mx-auto"
                @click="goToRoute(scholar.link, { duration: 300 })"
                style="border-radius: 30px"
              >
                <v-img :src="scholar.src" height="260px"></v-img>
                <v-card-title>
                  <p
                    style="color: white"
                    class="text-h6 font-weight-medium text-center mx-auto"
                    v-html="scholar.title"
                  ></p>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="mx-auto d-md-none"
          style="max-width: 950px; margin-top: -100px"
        >
          <vueper-slides
            autoplay
            class="no-shadow"
            fade
            duration="4000"
            :slide-ratio="1 / 2"
            fixed-height="463px"
            bullets-outside
            :arrows="false"
            :pause-on-hover="false"
            :pause-on-touch="false"
          >
            <vueper-slide
              v-for="(slide, i) in degrees"
              :key="i"
              :image="slide.src"
              class="slide-card mx-auto text-center"
              style="border-radius: 10px"
            >
              <template #content>
                <div class="content_card_slider content_card_slider-mobile">
                  <span
                    class="text-h6 font-weight-medium text-center mx-auto"
                    style="color: #fff"
                  >
                    {{ slide.title }}
                  </span>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </v-container>
      </div>
      <div class="my-8" />
      <v-img
        :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674620450/ahjende/landpage/cintilla-mishley_wv3vs8.jpg'"
        class="indexPhoto"
      />
      <contact-form />
      <why-ende />
      <div class="homeDescriptionContainer">
        <v-container>
          <v-row>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker4_n3mekg.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker3_b4essi.png'"
                class="descriptionImg"
                style="width: 100%; max-width: 65%"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker2_yeqhzr.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker1_izqu89.png'"
                class="descriptionImg"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <our-plantel />
      <chooseYourCDEComponent />
      <infoIndividualCDE />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import infoIndividualCDE from "@/components/infoIndividualCDE.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import OurPlantel from "@/components/stateless/OurPlantel.vue";
import WhyEnde from "@/components/stateless/WhyEnde.vue";
import contactForm from "@/components/newContactForm.vue";
import HeaderComponent from "../components/HomeHeader.vue";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

var setCoursesHeight;
// var setLocationDecorationHeight;
// var setLocationContainerPadding;

export default {
  name: "HomeView",

  title() {
    return `AHJ ENDE Inicio`;
  },

  data: () => ({
    loading: true,
    careers: [],
    courses: [],
    highschool: [],
    degrees: [
      {
        src: "/img/homeView/Emprende.webp",
        title: "Bachilleratos",
        link: "/oferta-bachilleratos",
      },
      {
        src: "/img/homeView/Licenciaturas.webp",
        title: "Licenciaturas",
        link: "/oferta",
      },
      {
        src: "/img/homeView/Diplomados.webp",
        title: "Diplomados",
        link: "/oferta-diplomados",
      },
      {
        src: "/img/homeView/Maestrias.webp",
        title: "Maestrías",
        link: "/oferta-maestrias",
      },
    ],
    bgImageArray: [
      "/img/homeView/Imagen1_prueba.jpg",
      "/img/homeView/Imagen2_prueba.jpg",
      "/img/homeView/Imagen3_recortado.jpg",
    ],
    valueIndex: 0,
    intervalId: null,
    parallax: 1,
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    contactForm,
    chooseYourCDEComponent,
    descriptionComponent,
    OurPlantel,
    WhyEnde,
    VueperSlides,
    VueperSlide,
    infoIndividualCDE,
  },
  created() {
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.index = true;
    this.getCareersData();
    this.header[["firstText"]] = "Cumple con tus";
    this.header[["secondText"]] = "objetivos profesionales";
    this.headerPhoto = this.bgImageArray[0];
    this.description =
      "Somos un grupo multidisciplinario especializado en el área de negocios. Tenemos más de 10 años de experiencia en el sector de la educación privada.";
  },

  beforeDestroy() {
    clearInterval(setCoursesHeight);
    // clearInterval(setLocationDecorationHeight);
    // clearInterval(setLocationContainerPadding);
    clearInterval(this.intervalId);
  },

  destroyed() {
    this.index = false;
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.setHeaderPhoto();
      }, 100);
    }, 100);
    this.changeHeaderPhoto();
  },
  beforeMount() {
    this.preloadImages();
  },
  methods: {
    preloadImages() {
      this.bgImageArray.forEach((img) => {
        new Image().src = img;
      });
    },
    changeHeaderPhoto() {
      this.headerPhoto = this.bgImageArray[this.valueIndex];
      this.intervalId = setInterval(() => {
        this.valueIndex = (this.valueIndex + 1) % this.bgImageArray.length;
        this.headerPhoto = this.bgImageArray[this.valueIndex];
        this.setHeaderPhoto();
      }, 4000);
    },
    getCareersData() {
      try {
        axios
          .get("/careers", {
            params: {},
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            this.careers = data[["careers"]];
          })
          .finally(() => {
            this.getCoursesData();
          });
      } catch (error) {
        console.log(error);
      }
    },

    getCoursesData() {
      try {
        axios
          .get("/courses", {
            params: {},
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            this.courses = data[["courses"]];
          })
          .finally(() => {
            this.getHighschoolData();
          });
      } catch (error) {
        console.log(error);
      }
    },

    getHighschoolData() {
      try {
        axios
          .get("/highschool", {
            params: {},
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            this.highschool = data[["highschool"]];
          })
          .finally(() => {
            this.loading = false;
            setTimeout(() => {
              // this.setContactBackground();
              setCoursesHeight = setInterval(this.setCoursesHeight, 100);
              // setLocationDecorationHeight = setInterval(
              //   this.setLocationDecorationHeight,
              //   100
              // );
              // setLocationContainerPadding = setInterval(
              //   this.setLocationContainerPadding,
              //   100
              // );
            }, 100);
          });
      } catch (error) {
        console.log(error);
      }
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setCoursesHeight() {
      var coursesDiv = window.document.getElementsByClassName("courseDiv");
      var courses = window.document.getElementsByClassName("course");
      var maxHeight = 0;
      for (let i = 0; i < courses.length; i++)
        if (courses[i].clientHeight > maxHeight)
          maxHeight = courses[i].clientHeight;
      for (let j = 0; j < courses.length; j++)
        coursesDiv[j].style.height = `${maxHeight}px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    // setLocationDecorationHeight() {
    //   var decoration = window.document.getElementById("descriptionDecoration");
    //   var container = window.document.getElementById("descriptionContainer");
    //   decoration.style.height = `${container.clientHeight}px`;
    // },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
      // header.style.background = `url(${this.headerPhoto}) no-repeat center center fixed`;
      // header.style.backgroundSize = "cover";
    },
    // setLocationContainerPadding() {
    //   var cdeContainer = window.document.getElementById(
    //     "descriptionComponentContainer"
    //   );
    //   var templateContainer = window.document.getElementById("footerContainer");
    //   var windowWidth = window.innerWidth;
    //   var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
    //   cdeContainer.style.marginRight = `${paddingElement}px`;
    //   cdeContainer.style.width = `${
    //     windowWidth > 959
    //       ? templateContainer.clientWidth / 2
    //       : templateContainer.clientWidth
    //   }px`;
    // },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },
    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },
    index: {
      get() {
        return this.$store.getters["indexModule/isIndexActive"];
      },
      set(newState) {
        if (this.$store.getters["indexModule/isIndexActive"] !== newState) {
          this.$store.dispatch("indexModule/toogleIndexState", newState);
        }
      },
    },
    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.customWidth {
  width: 90%;
}
.content_card_slider-mobile {
  height: 64px;
}
</style>
