<template>
  <!-- <v-parallax
    dark
    height="500"
    class="background-home"
    src="/img/licenciaturasView/headerPhoto.jpg"
  >
    <v-row justify="center">
      <v-col class="text-center" cols="12" align-self="end">
        <h1 class="text-h5 text-sm-h2 font-weight-regular mb-2">
          Cumple con tus
        </h1>
        <h4 class="text-h4 text-sm-h2 font-weight-black">
          objetivos profesionales
        </h4>
      </v-col>
      <v-col cols="12" sm="10" align-self="start">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            align="center"
            justify="center"
            v-for="(link, index) in links"
            :key="index"
          >
            <v-btn
              color="#00add1"
              class="homeButton font-weight-black pa-6 white--text text-subtitle-1 text-sm-h5"
              style="border-radius: 12px; text-transform: none"
              @click="goToRoute(link.link, { duration: 300 })"
              >{{ link.title }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-parallax> -->
  <div class="background-head" id="background-head">
    <div class="background-head-container">
      <v-container>
        <v-row
          style="z-index: 50; position: relative; height: 60vh"
          class="mx-auto"
          justify="center"
        >
          <v-col
            class="text-center"
            cols="10"
            align-self="end"
            style="color: #fff"
          >
            <h1 class="text-h5 text-sm-h2 font-weight-regular mb-0 mb-sm-2">
              Cumple con tus
            </h1>
            <h4 class="text-h4 text-sm-h2 font-weight-black">
              objetivos profesionales
            </h4>
          </v-col>
          <v-col cols="12" align-self="start" class="mx-auto">
            <v-row justify="center" class="mx-auto" style="max-width: 1300px">
              <v-col
                cols="12"
                sm="6"
                md="3"
                align="center"
                justify="center"
                v-for="(link, index) in links"
                :key="index"
              >
                <v-btn
                  color="#00add1"
                  class="homeButton font-weight-black pa-6 white--text text-subtitle-1 text-sm-h5"
                  style="border-radius: 12px; text-transform: none"
                  @click="goToRoute(link.link, { duration: 300 })"
                  >{{ link.title }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormHeaderComponent",
  components: {},
  data: () => ({
    links: [
      {
        title: "Bachilleratos",
        link: "/oferta-bachilleratos",
      },
      {
        title: "Licenciaturas",
        link: "/oferta",
      },
      {
        title: "Diplomados",
        link: "/oferta-diplomados",
      },
      {
        title: "Maestrías",
        link: "/oferta-maestrias",
      },
    ],
  }),
  methods: {
    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },
  },
  computed: {
    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },
  },
};
</script>

<style scoped>
.homeButton {
  width: 250px;
  color: #fff;
  font-weight: 900;
}
</style>
