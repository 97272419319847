<template>
  <div class="benefitsContainer mt-16">
    <v-container fluid>
      <v-row>
        <v-col
          cols="11"
          class="contactDescriptionText contactTitle font-weight-bold text-h5 text-md-h4 mb-sm-8 pb-0 mb-0"
        >
          ¿Por qu&eacute; estudiar en
          <br class="d-sm-none" />
          <span class="blueColorText font-weight-bold"> AHJ ENDE? </span>
        </v-col>
      </v-row>

      <v-row class="mx-auto d-sm-none">
        <v-carousel
          height="260"
          cycle
          interval="2500"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(slide, i) in textSlide" :key="i">
            <v-sheet height="100%" color="#000">
              <v-row class="fill-height" align="center" justify="center">
                <div class="cardBenefits mx-auto" style="width: 90%">
                  <p class="number">{{ i + 1 }}</p>
                  <p class="font-weight-medium">
                    {{ slide }}
                  </p>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>

      <v-row justify="center" class="cardBenefitsContainer d-none d-sm-flex">
        <v-col cols="12" sm="6" md="4" lg="3">
          <div class="cardBenefits">
            <p class="number">1</p>
            <p class="font-weight-medium">
              Experiencia empresarial y de negocios inmersiva para aprendizaje
              pr&aacute;ctico.
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <div class="cardBenefits">
            <p class="number">2</p>
            <p class="font-weight-medium">
              Plataforma y aplicaciones para tu uso 24/7 adapt&aacute;ndose a tu
              estilo de vida.
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <div class="cardBenefits">
            <p class="number">3</p>
            <p class="font-weight-medium">
              Modelo acad&eacute;mico pr&aacute;ctico para el desarrollo de
              habilidades &uacute;tiles en la vida de nuestros estudiantes.
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <div class="cardBenefits">
            <p class="number">4</p>
            <p class="font-weight-medium">
              Planes de estudio que se adaptan a tus tiempos y necesidades.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textSlide: [
        "Experiencia empresarial y de negocios inmersiva para aprendizaje práctico.",
        "Plataforma y aplicaciones para tu uso 24/7 adaptándose a tu estilo de vida.",
        "Modelo académico práctico para el desarrollo de habilidades útiles en la vida de nuestros estudiantes.",
        "Planes de estudio que se adaptan a tus tiempos y necesidades.",
      ],
    };
  },
};
</script>

<style scoped>
.cardBenefits {
  border: 2px solid #0092b7;
  border-radius: 15px;
  padding: 1.5rem;
  color: #fff;
}

.cardBenefitsContainer {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 720px) {
  .cardBenefits {
    height: 170px;
    padding: 1rem;
  }
  .cardBenefitsContainer {
    width: 95%;
    margin: 0 auto;
  }
}
</style>
