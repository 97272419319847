<template>
  <div id="results">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <v-container style="padding: 50pt 0">
        <v-row>
          <v-col col="12">
            <p class="titleComponent">¡Registrate!</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="10"
            md="8"
            lg="6"
            xl="4"
            style="margin: auto"
            v-for="(question, idx) in actionForm"
            :key="idx"
          >
            <label style="font-size: medium">
              {{ `¿Cuál es tu ${question[["label"]].toLowerCase()}?` }}
            </label>
            <v-text-field
              :label="question[['label']]"
              :placeholder="`Ingresa tu ${question[['label']].toLowerCase()}`"
              v-model="form[question[['model']]]"
              :rules="question[['rules']]"
              :loading="loadingForm"
              :disabled="loadingForm"
              v-on:keyup.enter="setForm()"
              v-if="question[['label']] !== 'Plan'"
            />
            <v-select
              :label="question[['label']]"
              :rules="question[['rules']]"
              clearable
              v-model="form[question[['model']]]"
              item-text="planName"
              item-value="planName"
              :items="plans"
              open-on-clear
              single-line
              outlined
              :loading="loadingForm"
              :disabled="loadingForm"
              v-else
            />
          </v-col>
          <v-col cols="10" style="margin: auto; text-align: center">
            <v-btn
              color="#0495b4"
              style="color: white"
              :loading="loadingForm"
              :disabled="loadingForm"
              v-on:click="setForm()"
            >
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import rules from "../assets/js/rules";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ResultsView",

  title() {
    return `AHJ ENDE Resultados Vocacionales`;
  },

  data: () => ({
    loading: true,
    loadingForm: false,
    form: {
      name: "",
      age: "",
      email: "",
      phone: "",
      plan: "",
    },
    actionForm: [
      {
        label: "Nombre",
        model: "name",
        rules: [rules.required],
      },
      {
        label: "Edad",
        model: "age",
        rules: [rules.required],
      },
      {
        label: "Correo Electrónico",
        model: "email",
        rules: [rules.required, rules.email],
      },
      {
        label: "Teléfono",
        model: "phone",
        rules: [rules.required],
      },
      {
        label: "Plan",
        model: "plan",
        rules: [rules.required],
      },
    ],
    plans: [
      {
        planName: "Prepa EMPR-ENDE (4 Meses)",
      },
      {
        planName: "Bachillerato negocios (24 meses)",
      },
      {
        planName: "Ingeniería industrial (3 años)",
      },
      {
        planName: "Ingeniería en sistemas (3  años)",
      },
      {
        planName:
          "Licenciatura en administración y estrategia  de negocios (3 años)",
      },
      {
        planName: "Licenciatura en psicología (3 años)",
      },
    ],
  }),

  validations: {
    form: {
      name: { required },
      age: { required },
      email: { required, email },
      phone: { required },
      plan: { required },
    },
  },

  components: {
    LottieAnimation,
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },

  methods: {
    setForm() {
      if (!this.$v.$invalid) {
        this.loadingForm = true;
        axios
          .post("/contact/register", this.form)
          .then((response) => {
            console.log(response.data);
            alert("Registro Exitoso");
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.form = {
              name: "",
              age: "",
              email: "",
              phone: "",
              plan: "",
            };
            this.loadingForm = false;
          });
      } else {
        alert("Debes de llenar la información completamente");
      }
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },
  },
};
</script>
