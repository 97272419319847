<template>
  <div>
    <v-container fluid class="singleCardContact">
      <v-col cols="12" class="mx-auto mt-md-6">
        <v-form ref="formContact">
          <v-row class="mx-auto formRow" no-gutters justify="center">
            <v-col cols="12" sm="12" class="mx-auto">
              <v-select
                label="Selecciona tu CDE"
                :rules="[rules.required]"
                clearable
                solo
                v-model="form[['cde']]"
                item-text="cde"
                item-value="cde"
                :items="cdeItems"
                open-on-clear
                single-line
                dense
                outlined
                class="contactFormInput"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <div class="pr-0 pr-sm-4">
                <v-text-field
                  outlined
                  solo
                  :rules="[rules.required]"
                  label="Nombre"
                  placeholder="Ingrese su nombre"
                  v-model="form[['name']]"
                  v-on:keyup.enter="getServices()"
                  dense
                  class="contactFormInput"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                :rules="[rules.required]"
                solo
                label="Teléfono"
                placeholder="Ingrese su teléfono"
                v-model="form[['phone']]"
                v-on:keyup.enter="getServices()"
                class="contactFormInput"
                dense
              />
            </v-col>
            <v-col cols="12" sm="6">
              <div class="pr-0 pr-sm-4">
                <v-text-field
                  solo
                  outlined
                  :rules="[rules.required, rules.email]"
                  label="Correo"
                  placeholder="Ingrese su correo"
                  v-model="form[['email']]"
                  dense
                  v-on:keyup.enter="getServices()"
                  class="contactFormInput"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                solo
                outlined
                :rules="[rules.required]"
                label="Ciudad"
                placeholder="Ciudad"
                v-model="form[['city']]"
                dense
                class="contactFormInput"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                :label="$store.getters['contactService/getQuestion']"
                :rules="[rules.required]"
                clearable
                solo
                v-model="form[['service']]"
                item-text="service"
                item-value="service"
                :items="$store.getters['contactService/getItems']"
                open-on-clear
                single-line
                outlined
                dense
                class="contactFormInput"
              />
            </v-col>
            <v-col cols="10" sm="8">
              <v-btn
                v-on:click="sendForm()"
                color="#00add1"
                block
                class="font-weight-black pa-6 white--text text-subtitle-1 text-sm-h5"
                style="border-radius: 12px; text-transform: none"
              >
                Quiero informes
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="mx-auto d-flex justify-center"
              id="checkbox"
            >
              <!-- <v-checkbox
                  :label="`He leído y acepto el ${privacyComponent}`"
                  :rules="[rules.required]"
                  v-model="privacyCheck"
                  color="#fff"
                  light
                /> -->
              <v-checkbox
                v-model="privacyCheck"
                :rules="[rules.required]"
                dark
                color="#fff"
              >
                <template v-slot:label>
                  <p
                    class="font-weight-bold text-caption text-sm-body-1"
                    style="color: #fff"
                  >
                    He leído y acepto el {{ privacyComponent }}
                  </p>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- <v-row>
          <v-col cols="11" style="margin: auto">
            <p class="titleComponent">¡Quiero informes, planes o servicios!</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-text-field
              outlined
              :rules="[rules.required]"
              label="Nombre *"
              placeholder="Ingrese su nombre"
              v-model="form[['name']]"
              v-on:keyup.enter="getServices()"
            />
          </v-col>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-text-field
              outlined
              :rules="[rules.required]"
              label="Apellido *"
              placeholder="Ingrese su apellido"
              v-model="form[['lastName']]"
              v-on:keyup.enter="getServices()"
            />
          </v-col>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-text-field
              outlined
              :rules="[rules.required]"
              label="Teléfono *"
              placeholder="Ingrese su teléfono"
              v-model="form[['phone']]"
              v-on:keyup.enter="getServices()"
            />
          </v-col>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-text-field
              outlined
              :rules="[rules.required, rules.email]"
              label="Correo *"
              placeholder="Ingrese su correo"
              v-model="form[['email']]"
              v-on:keyup.enter="getServices()"
            />
          </v-col>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-select
              label="Selecciona tu CDE"
              :rules="[rules.required]"
              clearable
              v-model="form[['cde']]"
              item-text="cde"
              item-value="cde"
              :items="cdeItems"
              open-on-clear
              single-line
              outlined
            />
          </v-col>
          <v-col cols="11" sm="6" md="4" style="margin: auto">
            <v-select
              label="¿Qué servicio te interesa?"
              :rules="[rules.required]"
              clearable
              v-model="form[['service']]"
              item-text="service"
              item-value="service"
              :items="items"
              open-on-clear
              single-line
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11" md="6" style="margin: auto" id="checkbox">
            <v-checkbox
              :label="`He leído y acepto el ${privacyComponent}`"
              :rules="[rules.required]"
              v-model="privacyCheck"
            />
          </v-col>
          <v-col
            cols="11"
            md="6"
            class="formsBtnColContainer"
            style="margin: auto"
          >
            <v-btn
              class="formsBtn"
              :disabled="enableButton"
              v-on:click="sendForm()"
            >
              Quiero Informes
            </v-btn>
          </v-col>
        </v-row> -->
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import rules from "../assets/js/rules";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ContactForm",

  data: () => ({
    privacyComponent: "Aviso de Privacidad",

    privacyCheck: false,

    form: {
      name: "",
      phone: "",
      email: "",
      cde: "",
      service: "",
      city: "",
    },

    rules: rules,

    cdeItems: [
      {
        cde: "Ecatepec",
      },
      {
        cde: "Naucalpan",
      },
      {
        cde: "Cuautitlán",
      },
      {
        cde: "Pachuca",
      },
      {
        cde: "Querétaro",
      },
      {
        cde: "San Luis Potosí",
      },
    ],

    items: [],
  }),

  validations: {
    privacyCheck: {
      checked(val) {
        return val;
      },
    },
    form: {
      name: { required },
      city: { required },
      phone: { required },
      email: { required, email },
      cde: { required },
      service: { required },
    },
  },

  created() {
    this.getServices();
  },

  mounted() {
    var labelContainer = window.document.querySelector("#checkbox");
    var label = labelContainer.querySelector("label");
    label.addEventListener("click", () => {
      this.privacyCheck = false;
      this.showDialog();
    });
  },

  methods: {
    showDialog() {
      this.goToRoute("/aviso-de-privacidad", "#privacy");
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    getServices() {
      var endpoints = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      endpoints.forEach((endpoint, idx) => {
        axios
          .get(endpoint, {
            params: {},
            headers: {},
          })
          .then((response) => {
            var data = response.data;
            var element = data[[wrapperPos[idx]]];
            element.forEach((service) => {
              this.items.push({
                service: service[["name"]],
              });
            });
          });
      });
    },

    sendForm() {
      if (!this.$v.$invalid) {
        // console.log(this.form);
        this.$v.$touch();
        this.sendEmail();
        this.clear();
      } else
        alert(
          "Debes de rellenar el formulario para saber tu beca. ¡Descubre lo que AHJ ENDE tiene para tí!"
        );
    },
    sendEmail() {
      const subject = `Quiero informes de ${this.form.service}`;
      const body = `Hola, soy ${this.form.name}, estoy interesado en información acerca de ${this.form.service} en ${this.form.cde}. \n\n Pueden contactarme al correo ${this.form.email} o al teléfono ${this.form.phone}.`;

      window.location.href = `mailto:contacto@ahjende.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
    },
    clear() {
      this.$refs.formContact.reset();
      // this.$v.$reset();
      this.form.name = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.cde = "";
      this.form.service = "";
      this.form.city = "";
      this.privacyCheck = false;
    },
  },

  computed: {
    enableButton() {
      return this.$v.$invalid ? true : false;
    },
  },
};
</script>

<style scoped>
.singleCardContact {
  background-color: rgba(45, 40, 40, 0.65);
  color: white;
  width: 100%;
  margin: 2rem auto;
  border-radius: 20px;
}

.titleCardContact {
  line-height: 1.3;
  padding: 0;
  font-size: 1.1em;
}

.cardContactImage {
  max-height: 200px;
  overflow: hidden;
  border-radius: 20px;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .formRow {
    width: 90%;
  }
  .singleCardContact {
    width: 95%;
  }
}
@media screen and (min-width: 720px) {
  .cardContactImage {
    max-width: 90%;
  }
  .titleCardContact {
    font-size: 2.4em;
  }
}
</style>
