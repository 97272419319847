<template>
  <div class="sidebar">
    <!-- <div> -->
    <a
      class="sidebar-element"
      :style="{ '--index': 0 }"
      v-on:click="
        weAre
          ? goTo('#we-are', { duration: 300 })
          : goToRoute('/somos', '#we-are');
        open = !open;
      "
    >
      <v-icon class="icon"> mdi-account-group </v-icon>
    </a>
    <a
      class="sidebar-element"
      :style="{ '--index': 1 }"
      v-on:click="
        cdes ? goTo('#cdes', { duration: 300 }) : goToRoute('/cdes', '#cdes');
        open = !open;
      "
    >
      <v-icon class="icon"> mdi-town-hall </v-icon>
    </a>
    <a
      class="sidebar-element"
      :style="{ '--index': 2 }"
      v-on:click="
        scholars
          ? goTo('#scholars', { duration: 300 })
          : goToRoute('/oferta', '#scholars');
        open = !open;
      "
    >
      <v-icon class="icon"> mdi-account-school </v-icon>
    </a>
    <a
      class="sidebar-element"
      :style="{ '--index': 3 }"
      v-on:click="
        scholarships
          ? goTo('#scholarships', { duration: 300 })
          : goToRoute('/becas', '#scholarships');
        open = !open;
      "
    >
      <v-icon class="icon"> mdi-school </v-icon>
    </a>
    <!-- <a
      class="sidebar-element"
      :style="{ '--index': 4 }"
      v-on:click="
        blog ? goTo('#blog', { duration: 300 }) : goToRoute('/blog', '#blog')
      "
    >
      <v-icon class="icon"> mdi-newspaper </v-icon>
    </a> -->
    <a
      class="sidebar-element"
      :style="{ '--index': 5 }"
      href="https://plataforma.ahjende.com/"
      target="_blank"
      v-on:click="open = !open"
    >
      <v-icon class="icon"> mdi-card-account-phone </v-icon>
    </a>
  </div>
</template>

<script>
export default {
  name: "sideComponent",

  data: () => ({}),

  methods: {
    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },

  computed: {
    blog: {
      get() {
        return this.$store.getters["blogModule/isBlogActive"];
      },
      set(newState) {
        if (this.$store.getters["blogModule/isBlogActive"] !== newState) {
          this.$store.dispatch("blogModule/toogleBlogState", newState);
        }
      },
    },

    cdes: {
      get() {
        return this.$store.getters["cdesModule/isCdesActive"];
      },
      set(newState) {
        if (this.$store.getters["cdesModule/isCdesActive"] !== newState) {
          this.$store.dispatch("cdesModule/toogleCdesState", newState);
        }
      },
    },

    index: {
      get() {
        return this.$store.getters["indexModule/isIndexActive"];
      },
      set(newState) {
        if (this.$store.getters["indexModule/isIndexActive"] !== newState) {
          this.$store.dispatch("indexModule/toogleIndexState", newState);
        }
      },
    },

    scholarships: {
      get() {
        return this.$store.getters["scholarshipsModule/isScholarshipsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarshipsModule/isScholarshipsActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "scholarshipsModule/toogleScholarshipsState",
            newState
          );
        }
      },
    },

    scholars: {
      get() {
        return this.$store.getters["scholarsModule/isScholarsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarsModule/isScholarsActive"] !== newState
        ) {
          this.$store.dispatch("scholarsModule/toogleScholarsState", newState);
        }
      },
    },

    open: {
      get() {
        return this.$store.getters["sideModule/isSideActive"];
      },
      set(newState) {
        if (this.$store.getters["sideModule/isSideActive"] !== newState) {
          this.$store.dispatch("sideModule/toogleSideState", newState);
        }
      },
    },

    weAre: {
      get() {
        return this.$store.getters["weAreModule/isWeAreActive"];
      },
      set(newState) {
        if (this.$store.getters["weAreModule/isWeAreActive"] !== newState) {
          this.$store.dispatch("weAreModule/toogleWeAreState", newState);
        }
      },
    },
  },
};
</script>
