var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('a',{staticClass:"sidebar-element",style:({ '--index': 0 }),on:{"click":function($event){_vm.weAre
        ? _vm.goTo('#we-are', { duration: 300 })
        : _vm.goToRoute('/somos', '#we-are');
      _vm.open = !_vm.open;}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-account-group ")])],1),_c('a',{staticClass:"sidebar-element",style:({ '--index': 1 }),on:{"click":function($event){_vm.cdes ? _vm.goTo('#cdes', { duration: 300 }) : _vm.goToRoute('/cdes', '#cdes');
      _vm.open = !_vm.open;}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-town-hall ")])],1),_c('a',{staticClass:"sidebar-element",style:({ '--index': 2 }),on:{"click":function($event){_vm.scholars
        ? _vm.goTo('#scholars', { duration: 300 })
        : _vm.goToRoute('/oferta', '#scholars');
      _vm.open = !_vm.open;}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-account-school ")])],1),_c('a',{staticClass:"sidebar-element",style:({ '--index': 3 }),on:{"click":function($event){_vm.scholarships
        ? _vm.goTo('#scholarships', { duration: 300 })
        : _vm.goToRoute('/becas', '#scholarships');
      _vm.open = !_vm.open;}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-school ")])],1),_c('a',{staticClass:"sidebar-element",style:({ '--index': 5 }),attrs:{"href":"https://plataforma.ahjende.com/","target":"_blank"},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-card-account-phone ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }