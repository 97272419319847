const state = {
  telfijo: "",
};
const getters = {
  isTelfijoActive(state) {
    return state.telfijo;
  },
};
const mutations = {
  changeTelfijoState(state, telfijo) {
    state.telfijo = telfijo;
  },
};
const actions = {
  toogleTelfijoState(context, telfijo) {
    context.commit("changeTelfijoState", telfijo);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
