<template>
  <div class="ourPlantel_component">
    <v-container fluid>
      <v-row>
        <v-col
          cols="11"
          md="4"
          class="order-2 order-md-1 imgCol pa-0 pr-md-3"
          style="margin: auto"
        >
          <div class="overflow_image">
            <v-carousel
              height="400"
              cycle
              interval="4000"
              hide-delimiter-background
              show-arrows-on-hover
              class="carrosual"
            >
              <v-carousel-item
                v-for="(slide, i) in items"
                :key="i"
                :src="slide.src"
              >
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col
          cols="11"
          md="8"
          class="order-1 order-md-2 weAreFlexElement background-grey cardOverflow mt-5 mt-md-0"
          style="margin: auto"
        >
          <v-row>
            <v-col cols="12" md="8" style="margin: auto">
              <p
                class="weAreTitle blueColorText text-md-h4 font-weight-black mt-6 mt-md-0"
              >
                Nuestras instalaciones
              </p>
            </v-col>
            <v-col cols="10" md="8" style="margin: auto">
              <p class="homeDescription">
                Nuestras instalaciones están diseñadas para brindar a nuestros
                alumnos espacios inmersivos para elevar la experiencia
                empresarial y de negocios.
                <br />
                <br />
                Espacios y áreas creadas especialmente para liberar todo el
                potencial de nuestros alumnos mediante el desarrollo de
                habilidades prácticas para la vida diaria ya sea como
                emprendedor o colaborando con alguna empresa.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "/img/instalaciones/instalaciones1.jpeg",
        },
        {
          src: "/img/instalaciones/instalaciones2.jpg",
        },
        {
          src: "/img/instalaciones/instalaciones3.jpg",
        },
        {
          src: "/img/instalaciones/instalaciones4.jpg",
        },
        {
          src: "/img/instalaciones/instalaciones5.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.ourPlantel_component {
  margin: 1.5rem 0;
}
@media screen and (min-width: 769px) {
  .ourPlantel_component {
    margin: 3.5rem 0;
  }

  .cardOverflow {
    height: 65vh;
  }

  .overflow_image {
    width: 125%;
  }
}
</style>
