<template>
  <div id="results">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col col="12">
            <p class="titleComponent">
              ¡Descubre los resultados del cuestionario de contacto!
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" style="margin: auto">
            <div class="componentSubtitle">Opción 1</div>
            <div class="subtitleDelimiter" />
            <apexcharts
              width="100%"
              height="350"
              type="donut"
              :options="chartOptions"
              :series="series"
            />
          </v-col>
          <v-col cols="12" style="text-align: center">
            <v-btn
              class="downloadBtn"
              text
              color="#0092b7"
              v-on:click="getCSV()"
            >
              Descarga los resultados del formulario
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ResultsView",

  title() {
    return `AHJ ENDE Resultados Vocacionales`;
  },

  data: () => ({
    loading: true,

    series: [],
    chartOptions: {
      labels: [],
      chart: {
        width: 380,
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: "right",
        offsetY: 0,
        height: 230,
      },
    },
  }),

  components: {
    LottieAnimation,
    apexcharts: VueApexCharts,
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      axios
        .get("/contact", {
          params: {
            limit: 1000000000,
          },
          headers: {},
        })
        .then((response) => {
          let data = response.data;
          let results = data.contacts;
          console.log(results);
          let op1 = [];
          let val1 = [];
          results.forEach((result) => {
            if (!op1.includes(result[["plan"]])) {
              op1.push(result[["plan"]]);
              val1.push(1);
            } else {
              let idx = op1.indexOf(result[["plan"]]);
              val1[idx] += 1;
            }
          });
          console.log(op1);
          console.log(val1);
          this.chartOptions.labels = op1;
          this.series = val1;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getCSV() {
      try {
        const url =
          "https://ahjende-api-server.onrender.com/v1/stage/contact/get-csv";
        const response = await axios.get(url, { responseType: "blob" });
        const blobURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = blobURL;
        link.setAttribute("download", "Resultados del Formulario.csv");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(blobURL);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },
  },
};
</script>
