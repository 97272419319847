const state = {
  entrecalles: "",
};
const getters = {
  isEntrecallesActive(state) {
    return state.entrecalles;
  },
};
const mutations = {
  changeEntrecallesState(state, entrecalles) {
    state.entrecalles = entrecalles;
  },
};
const actions = {
  toogleEntrecallesState(context, entrecalles) {
    context.commit("changeEntrecallesState", entrecalles);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
