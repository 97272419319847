const state = {
  horario: "",
};
const getters = {
  isHorarioActive(state) {
    return state.horario;
  },
};
const mutations = {
  changeHorarioState(state, horario) {
    state.horario = horario;
  },
};
const actions = {
  toogleHorarioState(context, horario) {
    context.commit("changeHorarioState", horario);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
