<template>
  <div id="we-are">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />
      <div class="weAreComponent">
        <v-container fluid>
          <v-row class="maxHeightLG" align="center" justify="center">
            <v-col
              cols="11"
              md="5"
              class="order-2 order-md-1 imgCol pa-0 pr-md-3"
              id="weArePhoto"
            >
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1675025753/ahjende/somosahj/WhatsApp_Image_2022-11-30_at_7.52.49_AM_q0vjun.jpg`"
                id="weAreDisplay"
                class="ahjWeAre"
              />
            </v-col>
            <v-col
              cols="11"
              md="7"
              class="order-1 order-md-2 weAreFlexElement background-grey cardOverflow mt-5 mt-md-0"
              style="margin: auto"
            >
              <v-row>
                <v-col cols="12" md="8" style="margin: auto">
                  <p
                    class="homeDescription d-none d-md-block text-h6 font-weight-black blueColorText"
                  >
                    Presidente ejecutivo de AHJ ENDE
                  </p>
                  <p
                    class="weAreTitle blueColorText text-md-h4 font-weight-black"
                  >
                    DHC. Antonio H. Jasso
                  </p>
                  <p
                    class="homeDescription d-block d-md-none blueColorText text-subtitle-1 pa-0 ma-0"
                    style="text-align: center"
                  >
                    Presidente ejecutivo de AHJ ENDE
                  </p>
                </v-col>
                <v-col cols="10" md="8" style="margin: auto">
                  <p class="homeDescription">
                    Nuestro logro m&aacute;s importante c&oacute;mo
                    instituci&oacute;n est&aacute; en cada uno de los
                    j&oacute;venes que egresan de nuestras aulas. Como
                    Instituci&oacute;n educativa tenemos la responsabilidad de
                    contribuir a la sociedad y qu&eacute; mejor que a
                    trav&eacute;s de la formaci&oacute;n de individuos
                    productivos con un enfoque de crecimiento continuo bajo una
                    filosofía de ganar - ganar.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- <div class="photoWeAreSeparator" /> -->
      <div class="homeDescriptionContainer">
        <v-container>
          <v-row>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker4_n3mekg.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker3_b4essi.png'"
                class="descriptionImg"
                style="width: 100%; max-width: 65%"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker2_yeqhzr.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker1_izqu89.png'"
                class="descriptionImg"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <contact-form />
      <why-ende />
      <div class="cdeComponent" style="margin-top: 2rem">
        <div class="cdeDecoration" id="weAreDecoration" />
        <v-container class="cdeContainer" id="weAreContainer">
          <v-row>
            <v-col cols="11" md="6" class="descriptionCol" style="margin: auto">
              <v-container id="weAreElementComponent">
                <v-row>
                  <v-col cols="12" md="11" style="margin: auto" class="px-0">
                    <p
                      class="cdeTitle blueColorText text-md-h4 font-weight-black px-0 px-md-10"
                    >
                      Nuestra historia
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="11" style="margin: auto" class="px-0">
                    <p
                      class="cdeDescription px-0 px-md-10"
                      style="text-align: justify"
                    >
                      Los principios de nuestra institución se remontan a hace
                      una década, cuando nuestro fundador, el DHC Antonio Jasso
                      decidió brindar a la población en general una nueva opción
                      para continuar estudios y formación especializada en
                      negocios, con un enfoque práctico y al alcance de todas
                      las personas. <br />
                      <br />
                      Siendo una institución educativa especializada en negocios
                      laica e incluyente hemos ayudado a más de 10,000 personas
                      a seguir estudiando para continuar avanzando en sus logros
                      personales y profesionales y tú puedes formar parte de
                      ellos.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="11" md="6" class="imgCol" style="margin: auto">
              <v-img src="/img/somosView/OurHistory.jpg" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="8"
            class="text-center font-weight-bold text-h4 text-md-h4 pa-0 mt-14 mx-auto"
            style="font-size: 23pt"
          >
            Filosof&iacute;a empresarial
          </v-col>
        </v-row>
      </v-container>
      <div class="mNVContainer">
        <v-container>
          <v-row>
            <v-col cols="11" md="6" style="margin: auto">
              <div class="cardmNV pa-6">
                <p class="mNVTitle">Misión</p>
                <div class="subtitleDelimiter" />
                <p class="text text-body-2 font-weight-medium">
                  Somos una empresa mexicana dedicada a desarrollar una cultura
                  empresarial dirigida a la sociedad en general, a trav&eacute;s
                  de nuestros distintos planes de estudio, con un modelo de
                  educaci&oacute;n disruptivo basado en la experiencia con el
                  objetivo de generar en cada uno de nuestros futuros lideres un
                  enfoque competitivo en el ambiente empresarial y de negocios.
                </p>
              </div>
            </v-col>
            <v-col cols="11" md="6" style="margin: auto">
              <div class="cardmNV pa-6">
                <p class="mNVTitle">Visión</p>
                <div class="subtitleDelimiter" />
                <p class="text text-body-2 font-weight-medium">
                  Ser la mejor opci&oacute;n en M&eacute;xico, para la formación
                  integral del individuo a nivel medio superior y superior;
                  desarrollando habilidades y competencias laborales,
                  empresariales y de negocios, que les permitan a nuestros
                  egresados mejorar su calidad de vida.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import ContactForm from "../components/newContactForm.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import WhyEnde from "@/components/stateless/WhyEnde.vue";

var setLocationDecorationHeight;
var setLocationContainerPadding;
var setPhotoHeight;

export default {
  name: "WeAreView",

  title() {
    return `AHJ ENDE Quienes Somos`;
  },

  data: () => ({
    loading: true,
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
    ContactForm,
    WhyEnde,
  },

  created() {
    this.header[["firstText"]] = "Somos";
    this.header[["secondText"]] = "AHJ ENDE";
    this.headerPhoto = "/img/somosView/headerSomos.jpeg";
    this.description =
      "Somos un grupo multidisciplinario especializado en el área de negocios. Tenemos más de 10 años de experiencia en el sector de la educación privada.";
    this.weAre = true;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.setHeaderPhoto();
        this.setDescriptionBackground();
        setLocationDecorationHeight = setInterval(
          this.setLocationDecorationHeight,
          100
        );
        setLocationContainerPadding = setInterval(
          this.setLocationContainerPadding,
          100
        );
        setPhotoHeight = setInterval(this.setPhotoHeight, 100);
      }, 100);
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
    clearInterval(setPhotoHeight);
  },

  destroyed() {
    this.weAre = false;
  },

  methods: {
    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "white";
      description.style.color = "black";
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("weAreDecoration");
      var container = window.document.getElementById("weAreContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var weAreELement = window.document.getElementById(
        "weAreComponentContainer"
      );
      var weAreContainer = window.document.getElementById(
        "weAreElementComponent"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      weAreELement.style.marginRight = `${paddingElement}px`;
      weAreELement.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
      weAreContainer.style.marginLeft = `${paddingElement}px`;
      weAreContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    setPhotoHeight() {
      var weAreContainer = window.document.getElementById("weAreContainer");
      var weArePhoto = window.document.getElementById("weArePhoto");
      var weAreDisplay = window.document.getElementById("weAreDisplay");
      weArePhoto.style.height = `${weAreContainer.clientHeight + 50}px`;
      weAreDisplay.style.height = `${weAreContainer.clientHeight + 50}px`;
    },
  },
  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },

    weAre: {
      get() {
        return this.$store.getters["weAreModule/isWeAreActive"];
      },
      set(newState) {
        if (this.$store.getters["weAreModule/isWeAreActive"] !== newState) {
          this.$store.dispatch("weAreModule/toogleWeAreState", newState);
        }
      },
    },
  },
};
</script>

<style scoped>
.cardmNV {
  border: 2px solid #0092b7;
  border-radius: 15px;
}

.cardBenefits {
  border: 2px solid #0092b7;
  border-radius: 15px;
  padding: 1.5rem;
}

@media screen and (min-width: 769px) {
  .cardOverflow {
    height: 60vh;
  }

  .cardmNV {
    height: 250px;
  }

  .maxHeightLG {
    max-height: 55vh;
  }
}
</style>
