<template>
  <div id="vocacional">
    <v-dialog v-model="modal" width="870" persistent>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <p class="formTitle">¡Has concluido el cuestionario!</p>
              <p
                style="
                  text-align: justify;
                  border-bottom: 2px solid rgba(4, 149, 180, 0.3);
                  padding: 20pt 0;
                  font-size: medium;
                "
              >
                Hemos llegado al final de este test de orientaci&oacute;n
                vocacional, un viaje de autoexploraci&oacute;n y descubrimiento
                en busca de nuestra verdadera vocaci&oacute;n. Esperamos que
                esta experiencia haya sido enriquecedora y haya proporcionado
                una mayor claridad sobre sus intereses, habilidades y valores
                personales.
              </p>
              <br />
              <p style="text-align: center">
                Los resultados para&nbsp;<strong>{{ name }}</strong
                >&nbsp;son:
              </p>
            </v-col>
            <v-col
              cols="11"
              md="6"
              :style="idx >= 2 ? 'weight: 0; padding: 0' : ''"
              style="margin: auto; text-align: center"
              v-for="(result, idx) in results"
              :key="idx"
            >
              <div :style="idx >= 2 ? 'weight: 0' : ''" v-if="idx < 2">
                <v-container>
                  <v-row>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-col
                          cols="12"
                          style="font-weight: bold; font-size: medium"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ `${result[["option"]]}` }}&nbsp;
                          <v-icon>mdi-information</v-icon>
                        </v-col>
                        <hr
                          style="width: 25%; margin: auto; align-items: center"
                        />
                      </template>
                      <span style="font-size: medium">
                        {{ `${result[["information"]]}` }}
                      </span>
                    </v-tooltip>
                  </v-row>
                </v-container>
              </div>
            </v-col>
            <v-col
              cols="11"
              md="6"
              :style="idx >= 2 ? 'weight: 0; padding: 0' : ''"
              style="margin: auto; text-align: justify"
              v-for="(result, idx) in results"
              :key="idx"
            >
              <div :style="idx >= 2 ? 'weight: 0' : ''" v-if="idx < 2">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      style="
                        font-size: medium;
                        border: 1px solid rgba(4, 149, 180, 0.6);
                        border-radius: 10px;
                        height: 50%;
                      "
                    >
                      <p>Carreras relacionadas:</p>
                      <br />
                      <p style="text-align: justify">
                        {{ `${result[["description"]]}` }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="descriptionText applyBtn"
              style="margin: auto; font-size: small"
            >
              <v-btn
                color="#0495b4"
                style="color: white"
                v-on:click="
                  (reset = true),
                    (closeForm = !closeForm),
                    (modal = !modal),
                    (modalende = !modalende)
                "
                target="_blank"
                block
              >
                Continuar...
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalende"
      width="auto"
      transition="dialog-top-transition"
      persistent
    >
      <v-card class="mx-auto" max-width="700">
        <v-container fluid>
          <v-row dense>
            <v-col v-for="card in cards" :key="card.index" :cols="card.flex">
              <v-card>
                <v-img
                  :src="card.src"
                  class="align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                  cover
                  v-on:click="goToRoute(`${card.link}`, '#careers')"
                >
                  <v-card-title style="color: gainsboro; font-size: medium">
                    {{ card.title }}
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-btn
              block
              size="small"
              class="mt-2 mb-2"
              v-on:click="modalende = !modalende"
              target="_blank"
              color="#0495b4"
              style="color: white"
              >Cerrar</v-btn
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <div id="descriptionContainer" style="padding: 35pt 0">
        <v-container>
          <v-row>
            <v-col cols="11" style="text-align: justify; margin: auto">
              <p>
                Un test de orientaci&oacute;n vocacional es una herramienta
                &uacute;til para explorar opciones profesionales, obtener una
                mejor comprensi&oacute;n de las preferencias y habilidades
                personales, y tomar decisiones m&aacute;s informadas sobre la
                elecci&oacute;n de una carrera. Ayuda a reducir la incertidumbre
                y brinda una base s&oacute;lida para la planificaci&oacute;n
                educativa y profesional. Sin embargo, es importante tener en
                cuenta que un test de orientaci&oacute;n vocacional es solo una
                herramienta y que otros factores, como la experiencia personal y
                la investigaci&oacute;n adicional, tambi&eacute;n son
                importantes en el proceso de toma de decisiones vocacionales.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="form">
        <v-row>
          <v-col cols="11" style="margin: auto">
            <p class="formTitle">¿Qu&eacute; tan apto te consideras para..?</p>
          </v-col>
          <v-col cols="10" style="margin: auto; text-align: justify">
            A continuaci&oacute;n, se presenta una lista de actividades comunes,
            sobre las cuales pudiste haber tenido alguna experiencia personal.
            Se desea que t&uacute; nos digas qu&eacute; tan apto te consideras
            para cada una de ellas. Ten encuenta que no preguntamos si te gustan
            las actividades citadas, sino si te consideras apto y en qu&eacute;
            grado para aprenderlas o desempe&ntilde;arlas.<br /><br />Es
            necesario que seas imparcial y justo en tus apreciaciones ya que se
            desea tener informes precisos sobre ti para ayudarte en tu proceso y
            tomes la mejor decisi&oacute;n. Te tomar&aacute; alrededor de 10
            minutos contestar el formulario. Recuerda responder las preguntas
            con honestidad.
          </v-col>
          <v-col cols="10" style="margin: auto">
            <v-text-field
              label="¿Cuál es tu nombre?"
              :rules="[rules.required]"
              placeholder="Ingresa tu nombre, por favor."
              v-model="name"
              style="font-size: small"
              :disabled="!closeForm"
            />
          </v-col>
          <v-col cols="10" style="margin: auto">
            <v-text-field
              label="¿Cuál es tu correo?"
              :rules="[rules.required]"
              placeholder="Ingresa tu correo electrónico, por favor."
              v-model="email"
              style="font-size: small"
              :disabled="!closeForm"
            />
          </v-col>
          <v-col cols="10" style="margin: auto">
            <v-text-field
              label="¿Cuál es tu teléfono?"
              placeholder="Ingresa tu teléfono, por favor."
              v-model="phone"
              v-mask="'##########'"
              style="font-size: small"
              :disabled="!closeForm"
            />
          </v-col>
          <v-col cols="10" style="margin: auto">
            <v-select
              label="Selecciona tu campus"
              :rules="[rules.required]"
              clearable
              v-model="campus"
              item-text="campus"
              item-value="campus"
              style="font-size: small"
              :items="campusItems"
              open-on-clear
              single-line
              :disabled="!closeForm"
            />
          </v-col>
          <v-col
            cols="10"
            style="margin: auto; text-align: center"
            v-if="closeForm"
          >
            <v-btn color="#0495b4" style="color: white" v-on:click="openForm()">
              Accede al formulario
            </v-btn>
          </v-col>
          <v-col
            cols="10"
            :class="{ closeForm: closeForm }"
            style="margin: auto"
          >
            <v-tabs v-model="tab" align-with-title center-active>
              <v-tabs-slider color="#0092b7" />
              <v-tab v-for="(_header, idx) in question" :key="idx">
                {{ idx === 0 ? `1 - ${idx + 1}0` : `${idx}1 - ${idx + 1}0` }}
              </v-tab>
            </v-tabs>
            <div>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    v-for="(header, idx) in question[tab]"
                    :key="idx"
                  >
                    <label style="font-size: medium">
                      {{ header[["placeholder"]] }}
                    </label>
                    <v-select
                      label="Selecciona tu respuesta"
                      v-model="
                        form[
                          [
                            tab === 0
                              ? `${idx + 1}`
                              : idx + 1 === 10
                              ? `${tab * 10 + idx + 1}`
                              : `${tab}${idx + 1}`,
                          ]
                        ]
                      "
                      :rules="[rules.required]"
                      item-text="question"
                      item-value="weight"
                      :items="header[['values']]"
                      clearable
                      open-on-clear
                      single-line
                      outlined
                      style="font-size: small"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-tabs v-model="tab" align-with-title center-active>
              <v-tabs-slider color="#0092b7" />
              <v-tab v-for="(_header, idx) in question" :key="idx">
                {{ idx === 0 ? `1 - ${idx + 1}0` : `${idx}1 - ${idx + 1}0` }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            cols="11"
            class="descriptionText applyBtn"
            style="margin: auto"
          >
            <v-btn
              color="#0495b4"
              style="color: white"
              v-on:click="makePrediction()"
              v-if="!closeForm"
            >
              Obtener Resultado
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <div class="instalationComponent">
        <div class="scholarshipsComponent" id="descriptionDecoration" />
        <v-container id="descriptionContainerText">
          <v-row>
            <v-col
              cols="11"
              md="6"
              class="order-2 order-md-1 imgCol"
              style="margin: auto"
            >
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1685068739/ahjende/orientacion_vocacional/orientacion_10101_lnllik.jpg`"
              />
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="order-1 order-md-2 descriptionColContainer"
              style="margin: auto"
            >
              <v-container id="descriptionComponentContainer">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p
                      class="homeDescription"
                      style="color: #e6e6e6; font-size: large"
                    >
                      Recuerden que este test es solo una herramienta para
                      guiarlos en su camino. No es un dictamen definitivo ni una
                      garant&iacute;a de &eacute;xito en nuestra elecci&oacute;n
                      profesional. Es importante tener en cuenta que su
                      vocaci&oacute;n puede evolucionar con el tiempo y que no
                      hay respuestas absolutas ni caminos predefinidos. La vida
                      está llena de oportunidades y desafíos que pueden
                      llevarlos por caminos inesperados, y es en ese proceso
                      donde realmente se encuentra el crecimiento personal y
                      profesional.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <contact-form />
      <chooseYourCDEComponent />
      <CDESChooserComponent />
      <courses-wrapper-component />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import CDESChooserComponent from "../components/CDESChooserComponent.vue";
import contactForm from "../components/ContactForm.vue";
import coursesWrapperComponent from "../components/coursesWrapperComponent.vue";
import question from "../assets/js/vocacionalQuestion";
import rules from "../assets/js/rules";
import { required, email } from "vuelidate/lib/validators";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mask, type } from "vue-the-mask";

var setLocationContainerPadding;
var setLocationDecorationHeight;

export default {
  name: "VocacionalView",

  title() {
    return `AHJ ENDE Orientación Vocacional`;
  },

  data: () => ({
    reset: false,
    tab: 0,
    loading: true,
    name: "",
    email: "",
    campus: "",
    phone: "",
    results: [],
    closeForm: true,
    rules: rules,
    form: {},
    modal: false,
    modalende: false,
    question: question,
    campusItems: [
      {
        campus: "CETIS1",
      },
      {
        campus: "Otro",
      },
    ],
    categories: [
      {
        category: "Servicio Social",
        description:
          "Urbanismo, Ingeniería Civil, Sociología, Trabajo Social, Derecho, Enfermería y Obstetricia, Psicología, Pedagogía, Medicina, Odontología, Ciencias Políticas y Administración Pública, Economía, Relaciones Internacionales, Enseñanza de Inglés, Optometría, Planificación para el Desarrollo Agropecuario, Estudios Latinoamericanos, Bibliotecología y Estudios de la Información, Educación Musical.",
        info: "Preferencia por participar en actividades directamente relacionadas con el bienestar de las personas.",
      },
      {
        category: "Ejecutivo Persuasiva",
        description:
          "Actuaría, Economía, Administración, Ciencias Políticas y Administración Pública, Derecho, Ingeniería Industrial, Ingeniería de Alimentos, Ingeniería Petrolera, Psicología, Medicina, Relaciones Internacionales.",
        info: "Agrado por planear, organizar o dirigir las actividades de personas o agrupaciones.",
      },
      {
        category: "Verbal",
        description:
          "Derecho, Ciencias de la Comunicación, Letras   Clásicas,   Lengua	y   Literaturas Modernas,	Relaciones	Internacionales, Literatura Dramática y Teatro, Sociología, Ciencias Políticas y Administración Pública.",
        info: "Gusto por la lectura de obras diversas y	satisfacción	al	expresarse verbalmente o por escrito.",
      },
      {
        category: "Artístico Plástica",
        description:
          "Artes Visuales, Diseño y comunicación Visual, Diseño Gráfico, Arquitectura, Arquitectura de Paisaje, Odontología, Literatura Dramática y Teatro.",
        info: "Agrado por conocer o realizar actividades creativas como dibujo, pintura, escultura, modelado, etcétera.",
      },
      {
        category: "Musical",
        description:
          "Composición, Instrumentista, Canto, Etnomusicología, Piano, Educación Musical.",
        info: "Gusto por la ejecución, estudio o composición de la música.",
      },
      {
        category: "Organización",
        description:
          "Bibliotecología y Estudios de la Información, Actuaría, Matemáticas Aplicadas y Computación, Informática, Contaduría, Administración, Ciencias de la Comunicación, Matemáticas, Relaciones Internacionales, Economía, Ciencias Políticas y Administración Pública.",
        info: "Preferencia	por	actividades	que requieran orden y sistematización.",
      },
      {
        category: "Científica",
        description:
          "Investigación Biomédica Básica, Ciencias Genómicas, Matemáticas, Física, Ingeniería Mecatrónica, Química, Biología, Psicología, Medicina Veterinaria y Zootecnia, Ingeniería Química, Química Farmacéutico-Biológica,	Química Industrial, Química de Alimentos, Ingeniería en Alimentos, Filosofía, Historia.",
        info: "Gusto por conocer o investigar la razón de ser de los fenómenos, las causas que los provocan y los principios que los explican.",
      },
      {
        category: "Cálculo",
        description:
          "Matemáticas, Economía, Contaduría, Física, Ingenierías: Geológica, Geofísica, Civil,	en	Telecomunicaciones, Computación, Topográfica, Industrial, Química; Arquitectura, Geografía, Actuaría, Informática, Química, Matemáticas Aplicadas y Computación, Ciencias de la Comunicación.",
        info: "Gusto por resolver problemas de tipo cuantitativo, en donde intervienen las operaciones matemáticas.",
      },
      {
        category: "Mecánico Constructiva",
        description:
          "Ingenierías:	Eléctrica-Electrónica, Geofísica, Topográfica, Civil, Petrolera, Mecánica Eléctrica, Química, en Computación, Mecánica Química Metalúrgica, Mecatrónica, Arquitectura, Diseño Industrial.",
        info: "Atracción por armar, conocer o descubrir mecanismos por los cuales funciona un aparato, y por proyectar y construir objetos diversos.",
      },
      {
        category: "Aire Libre",
        description:
          "Biología, Ingeniería Agrícola, Ingeniería Geológica, Ingeniería Petrolera, Geografía, Ingeniería Civil, Ingeniería Topográfica y Geodésica, Medicina Veterinaria y Zootecnia, Planificación para el Desarrollo Agropecuario, Urbanismo.",
        info: "Satisfacción por actividades que se realizan en lugares abiertos, apartados de los conglomerados urbanos.",
      },
      {
        category: "Destreza Manual",
        description:
          "Piano, artes visuales, cirujano dentista (odontología), médico cirujano, arquitectura y diseño gráfico.",
        info: "Atracción por actividades manuales y de concentración visual con fines de ayuda a las personas.",
      },
    ],
    cards: [
      {
        title: "",
        src: "https://res.cloudinary.com/interprocsysmex/image/upload/v1673644265/ahjende/ofertaeducativa/ofedu-img1_nn5h4h.jpg",
        link: "#",
        flex: 12,
        index: 1,
      },
      {
        title: "",
        src: "https://res.cloudinary.com/interprocsysmex/image/upload/v1686359579/ahjende/ofertaeducativa/oferta-ede-1_tqftdz.jpg",
        link: "/carreras?career=Licenciatura%20en%20Administración%20y%20Estrategias%20de%20negocios",
        flex: 6,
        index: 2,
      },
      {
        title: "",
        src: "https://res.cloudinary.com/interprocsysmex/image/upload/v1686359579/ahjende/ofertaeducativa/oferta-ende-2_j83dzb.jpg",
        link: "/carreras?career=Licenciatura%20en%20Psicología",
        flex: 6,
        index: 3,
      },
    ],
  }),

  watch: {
    reset() {
      if (this.reset === true) {
        this.tab = 0;
        this.name = "";
        this.email = "";
        this.campus = "";
        this.phone = "";
        this.results = [];
        this.form = {};
        this.reset = false;
      }
    },
  },

  validations: {
    name: { required },
    email: { required, email },
    campus: { required },
  },

  components: {
    LottieAnimation,
    HeaderComponent,
    chooseYourCDEComponent,
    CDESChooserComponent,
    contactForm,
    coursesWrapperComponent,
  },

  directives: {
    mask,
    type,
  },

  created() {
    this.getSearchElements();
    this.header[["firstText"]] = "Orientación";
    this.header[["secondText"]] = "Vocacional";
    this.headerPhoto =
      "https://res.cloudinary.com/interprocsysmex/image/upload/v1685162123/ahjende/orientacion_vocacional/orientacion_10100_hyasq90_fmyrwh.jpg";
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.vocacional = true;
  },

  beforeDestroy() {
    clearInterval(setLocationContainerPadding);
    clearInterval(setLocationDecorationHeight);
  },

  destroyed() {
    this.vocacional = false;
  },

  methods: {
    getSearchElements() {
      var end = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      var rand = Math.floor(Math.random() * 3);
      axios
        .get(end[rand], {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[[wrapperPos[rand]]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            this.setDescriptionBackground();
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
            setLocationDecorationHeight = setInterval(
              this.setLocationDecorationHeight,
              100
            );
          }, 100);
        });
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("descriptionDecoration");
      var container = window.document.getElementById(
        "descriptionContainerText"
      );
      decoration.style.height = `${container.clientHeight}px`;
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "black";
      description.style.color = "#c2c2c2";
    },

    setLocationContainerPadding() {
      var cdeContainer = window.document.getElementById(
        "descriptionComponentContainer"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      cdeContainer.style.marginRight = `${paddingElement}px`;
      cdeContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    openForm() {
      if (!this.$v.$invalid) {
        this.closeForm = false;
      } else {
        alert("No debes dejar campos en blanco. Revisa y continua, por favor.");
      }
    },
    async makePrediction() {
      if (!this.$v.$invalid && Object.keys(this.form).length === 60) {
        let formKeys = Object.keys(this.form);
        let subForms = [];
        let val = [];
        for (let i = 0; i < 60; i += 10) {
          var subArr = formKeys.slice(i, i + 10);
          subForms.push(subArr);
        }
        subForms.forEach((subForm, jdx) => {
          subForm.forEach((element, idx) => {
            var weight = this.form[[element]] === -1 ? 0 : element;
            if (jdx === 0)
              val.push({
                option: this.categories[idx][["category"]],
                description: this.categories[idx][["description"]],
                information: this.categories[idx][["info"]],
                val: parseInt(weight),
              });
            else val[idx][["val"]] += parseInt(weight);
          });
        });
        val.sort(this.orderArr);
        this.results = val;
        this.modal = true;
        const rawResponse = await fetch(
          "https://ahjende-api-server.onrender.com/v1/stage/vocational",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: this.name,
              email: this.email,
              phone: this.phone,
              campus: this.campus,
              option1: this.results[0][["option"]],
              option2: this.results[1][["option"]],
            }),
          }
        );
        const content = await rawResponse.json();
        console.log(content);
      } else {
        alert("No debes dejar campos en blanco. Revisa y continua, por favor.");
      }
    },

    orderArr(a, b) {
      if (a.val > b.val) {
        return -1;
      } else if (a.val < b.val) {
        return 1;
      } else {
        return 0;
      }
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    vocacional: {
      get() {
        return this.$store.getters["vocacionalModule/isVocacionalActive"];
      },
      set(newState) {
        if (
          this.$store.getters["vocacionalModule/isVocacionalActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "vocacionalModule/toogleVocacionalState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>
