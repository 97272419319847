<template>
  <div id="cdes">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <div class="cdeComponent">
        <v-container class="cdeMain">
          <v-row>
            <v-col cols="11" md="6" class="descriptionCol" style="margin: auto">
              <v-container id="cdeMainComponent">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeTitle">¿Qu&eacute; es un CDE?</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeDescription" style="text-align: justify">
                      CDE refiere a las siglas de Centro de Desarrollo
                      Empresarial; cómo parte de nuestra filosof&iacute;a y
                      modelo de ense&ntilde;anza, hemos dise&ntilde;ado espacios
                      y &aacute;reas creadas especialmente para liberar todo el
                      potencial de nuestros alumnos mediante el desarrollo de
                      habilidades pr&aacute;cticas para la vida diaria ya sea
                      c&oacute;mo emprendedor o colaborando con alguna empresa.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="11" md="6" class="imgCol" style="margin: auto">
              <v-img :src="`/img/cdesView/cdes1.jpeg`" />
              <!-- <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1681271536/ahjende/cdes/FotoENDE_tvgpea.jpg`"
              /> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div style="background: #0092b7; color: white; font-weight: bold">
        <v-container>
          <v-row>
            <v-col cols="8" class="descriptionText contactTitle">
              Nuestras CDE's
            </v-col>
          </v-row>
        </v-container>
      </div>
      <description-component />
      <chooseYourCDEComponent />
      <infoIndividualCDE />
      <div class="ourPlantel_component">
        <v-container fluid id="containter_Testing">
          <v-row>
            <v-col
              cols="11"
              md="6"
              class="weAreFlexElement background-grey cardOverflow mx-auto"
            >
              <v-row>
                <v-col cols="12" md="8" style="margin: auto">
                  <p
                    class="weAreTitle blueColorText text-md-h4 font-weight-black mt-6 mt-md-0"
                    style="position: relative !important; opacity: 1 !important"
                  >
                    Modelo inmersivo
                  </p>
                </v-col>
                <v-col cols="10" md="8" style="margin: auto">
                  <p
                    class="homeDescription"
                    style="position: relative !important; opacity: 1 !important"
                  >
                    Nuestras instalaciones están diseñadas para brindar a
                    nuestros alumnos espacios inmersivos para elevar la
                    experiencia empresarial y de negocios.
                    <br />
                    <br />
                    Espacios y áreas creadas especialmente para liberar todo el
                    potencial de nuestros alumnos mediante el desarrollo de
                    habilidades prácticas para la vida diaria ya sea como
                    emprendedor o colaborando con alguna empresa.
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11" md="6" class="pa-0" style="margin: auto">
              <v-img src="/img/cdesView/cdes2.jpeg" class="image_size" />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <contact-form />
    </div>
  </div>
</template>

<script>
import descriptionComponent from "../components/descriptionComponent.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import contactForm from "../components/newContactForm.vue";
import infoIndividualCDE from "@/components/infoIndividualCDE.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

var setLocationDecorationHeight;
var setLocationContainerPadding;

export default {
  name: "CDESView",

  title() {
    return `AHJ ENDE CDES`;
  },

  data: () => ({
    loading: true,
  }),

  components: {
    LottieAnimation,
    descriptionComponent,
    chooseYourCDEComponent,
    contactForm,
    infoIndividualCDE,
  },

  created() {
    this.description =
      "Los Centros de Desarrollo Empresarial son los espacios en donde llevarás a cabo tu formación y estamos seguros de que se volverá tu segunda casa.";
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.cdes = true;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.setContactBackground();
        this.setDescriptionBackground();
        this.setCDEBtnWidth();
        setLocationDecorationHeight = setInterval(
          this.setLocationDecorationHeight,
          100
        );
        setLocationContainerPadding = setInterval(
          this.setLocationContainerPadding,
          100
        );
      }, 100);
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
  },

  destroyed() {
    this.cdes = false;
  },

  methods: {
    setCDEBtnWidth() {
      var cdeBtns = window.document.getElementsByClassName("cdeBtn");
      var maxWidth = 0;
      for (let i = 0; i < cdeBtns.length; i++)
        if (cdeBtns[i].clientWidth > maxWidth)
          maxWidth = cdeBtns[i].clientWidth;
      for (let j = 0; j < cdeBtns.length; j++)
        cdeBtns[j].style.width = `${maxWidth}px`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "white";
      description.style.color = "#0c0c0c";
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("cdeDecoration");
      var container = window.document.getElementById("cdeContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var cdeMain = window.document.getElementById("cdeMainComponent");
      var cdeContainer = window.document.getElementById("cdeElementComponent");
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      cdeMain.style.marginLeft = `${paddingElement}px`;
      cdeMain.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
      cdeContainer.style.marginLeft = `${paddingElement}px`;
      cdeContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    cdes: {
      get() {
        return this.$store.getters["cdesModule/isCdesActive"];
      },
      set(newState) {
        if (this.$store.getters["cdesModule/isCdesActive"] !== newState) {
          this.$store.dispatch("cdesModule/toogleCdesState", newState);
        }
      },
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.ourPlantel_component {
  margin: 1.5rem 0;
}

#containter_Testing {
  position: relative;
}

@media screen and (min-width: 780px) {
  .ourPlantel_component {
    margin: 3.5rem 0;
  }

  .cardOverflow {
    height: 65vh;
  }

  .image_size {
    max-height: 55vh;
  }

  #containter_Testing::before {
    content: "";
    background-color: #e0e0e0;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 60%;
  }
}
</style>
