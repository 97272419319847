<template>
  <div class="mt-4">
    <v-container fluid class="mx-auto">
      <v-row>
        <v-col cols="11" md="6" class="ma-auto pa-0">
          <div
            class="mapsCDE_background"
            style="height: 60vh"
            id="background_cde"
          >
            <v-container style="position: relative; z-index: 2">
              <v-row class="mx-sm-auto auto-width">
                <v-col cols="11" style="margin: auto">
                  <p
                    class="cde_maps_title text-h5 text-md-h4 font-weight-black"
                  >
                    {{ cde[["title"]] }}
                  </p>
                </v-col>
              </v-row>
              <v-row style="width: 75%" class="mx-sm-auto">
                <v-col
                  cols="11"
                  sm="6"
                  style="margin: auto"
                  v-for="(cdeBtn, i) in cdesPlace"
                  :key="i"
                  class="pa-0"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      text
                      plain
                      x-large
                      v-on:click="
                        setData(
                          cdeBtn[['map']],
                          cdeBtn[['horario']],
                          cdeBtn[['telfijo']],
                          cdeBtn[['entrecalles']],
                          cdeBtn[['name']]
                        );
                        setBackgroundImage(cdeBtn.backgroundImage);
                      "
                      class="text-h6 text-sm-h5 font-weight-bold button-select-cde"
                      :color="
                        hover || selectedCDE == cdeBtn[['name']]
                          ? '#0495b4'
                          : '#ccd4d6'
                      "
                    >
                      <v-icon>
                        {{ "mdi-chevron-right" }}
                      </v-icon>
                      {{ cdeBtn[["name"]] }}<br />
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col
          cols="11"
          md="6"
          class="padding-mapa"
          style="margin: auto; z-index: 2"
        >
          <div class="canvas-mapa">
            <div class="google-maps">
              <iframe
                :src="map"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "chooseYourCDEComponent",

  data: () => ({
    cdesPlace: [
      {
        name: "Ecatepec",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.621461159961!2d-99.0470744!3d19.6007116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f1a2c35931d1%3A0xba3e6a0eab745a32!2sInstituto%20AHJ-ENDE!5e0!3m2!1ses-419!2smx!4v1681539135501!5m2!1ses-419!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-7651-55-35 / 55-5770-5267 / 55-8406-7999",
        entrecalles: "Pensadores mexicanos y Benito Juárez Norte",
        backgroundImage: "/img/mapsCDE/ecatepec.jpeg",
      },
      {
        name: "Cuautitlán",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.9933479543038!2d-99.1877379354111!3d19.670289186749734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f5944f94fe1f%3A0x127ced6e6eaad00a!2sAHJ%20ENDE%20CUAUTITLAN!5e0!3m2!1ses!2smx!4v1676301063011!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-6731-0978 / 56-2098-5195 / 55-8567-3027",
        entrecalles: "Av. 16 de Septiembre y 5 de Mayo",
        backgroundImage: "/img/mapsCDE/cuatitlan.jpeg",
      },
      {
        name: "Pachuca",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3746.299175084555!2d-98.74247467796359!3d20.12156254330637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109fb397279e9%3A0x183bd6983664cf7a!2sCalle%20Gral.%20Vicente%20Segura%20307-LT%2011%2C%20Centro%2C%2042060%20Pachuca%20de%20Soto%2C%20Hgo.!5e0!3m2!1ses!2smx!4v1676301116997!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "771-6098-348 / 56-1349-3915 / 771-2991-696",
        entrecalles: "Profa Laura Lugo y Gral. Daniel Cerecedo Estrada",
        backgroundImage: "/img/mapsCDE/pachuca.jpeg",
      },
      {
        name: "Naucalpan",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.7583718431592!2d-99.22829578541477!3d19.46598188686538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20251b3489c8b%3A0x9158b5f05c82aa7d!2sAv.%20Primero%20de%20Mayo%2015%2C%20San%20Andres%20Atoto%2C%2053500%20Naucalpan%20de%20Ju%C3%A1rez%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1676301008399!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-7677-9111 / 771-3316-189 / 56-2436-3683",
        entrecalles: "C. Hormona y Blvd. Toluca",
        backgroundImage: "/img/mapsCDE/naucalpan.jpeg",
      },
      {
        name: "Querétaro",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.0288381138903!2d-100.39837788539441!3d20.586879886239398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d3452a937e8a9d%3A0xbd3c0da159b98e11!2sAv.%20Zaragoza%20Pte.%2067%2C%20Centro%2C%2076000%20Santiago%20de%20Quer%C3%A9taro%2C%20Qro.!5e0!3m2!1ses!2smx!4v1676301161775!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "442-2404-101 / 55-3700-8180 / 442-8473-531",
        entrecalles: "C. Ignacio Perez y C. Nicolás Campa",
        backgroundImage: "/img/mapsCDE/queretaro.jpeg",
      },
      {
        name: "San Luis Potosí",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14781.24696487014!2d-100.99357100054405!3d22.152202519242078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842aa201fc90fffb%3A0x318299fedfad4ff6!2sCentro%20Historico%2C%2078000%20San%20Luis%2C%20S.L.P.!5e0!3m2!1ses!2smx!4v1676301259776!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "444-1391-089 / 55-7946-6651 / 444-2901-569",
        entrecalles: "Av. Universidad y Vicente Guerrero",
        backgroundImage: "/img/mapsCDE/ecatepec.jpeg",
      },
    ],
  }),
  mounted() {
    this.setData(
      this.cdesPlace[0].map,
      this.cdesPlace[0].horario,
      this.cdesPlace[0].telfijo,
      this.cdesPlace[0].entrecalles,
      this.cdesPlace[0].name
    );
  },
  methods: {
    setBackgroundImage(src) {
      var decoration = window.document.getElementById("background_cde");
      decoration.style.backgroundImage = `url('${src}')`;
      // decoration.style.setProperty("--background-image", `url('${src}')`);
    },
    setData(_map, _horario, _telfijo, _entrecalles, _name) {
      this.map = _map;
      this.horario = _horario;
      this.telfijo = _telfijo;
      this.entrecalles = _entrecalles;
      this.selectedCDE = _name;
    },
  },

  computed: {
    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },
    selectedCDE: {
      get() {
        return this.$store.getters["cdeModule/selectedCde"];
      },
      set(newState) {
        this.$store.dispatch("cdeModule/toggleSelectedCDE", newState);
      },
    },
    map: {
      get() {
        return this.$store.getters["mapModule/isMapActive"];
      },
      set(newState) {
        if (this.$store.getters["mapModule/isMapActive"] !== newState) {
          this.$store.dispatch("mapModule/toogleMapState", newState);
        }
      },
    },
    horario: {
      get() {
        return this.$store.getters["horarioModule/isHorarioActive"];
      },
      set(newState) {
        if (this.$store.getters["horarioModule/isHorarioActive"] !== newState) {
          this.$store.dispatch("horarioModule/toogleHorarioState", newState);
        }
      },
    },
    telfijo: {
      get() {
        return this.$store.getters["telfijoModule/isTelfijoActive"];
      },
      set(newState) {
        if (this.$store.getters["telfijoModule/isTelfijoActive"] !== newState) {
          this.$store.dispatch("telfijoModule/toogleTelfijoState", newState);
        }
      },
    },
    entrecalles: {
      get() {
        return this.$store.getters["entrecallesModule/isEntrecallesActive"];
      },
      set(newState) {
        if (
          this.$store.getters["entrecallesModule/isEntrecallesActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "entrecallesModule/toogleEntrecallesState",
            newState
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.cde_maps_title {
  color: #0495b4;
  position: relative;
}

.mapsCDE_background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("/public/img/mapsCDE/ecatepec.jpeg");
  background-size: cover;
  /* transition: background-image 1s ease-in-out; */
  transition: all 1s ease-in-out;
  background-position-x: center;
  background-position-y: bottom;
}

#background_cde::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 720px) {
  .auto-width {
    width: 75%;
  }
}
@media screen and (min-width: 780px) {
  .mapsCDE_background {
    width: 125%;
    max-width: 125%;
  }
}
</style>
