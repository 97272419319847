<template>
  <div class="background-head" id="background-head">
    <div class="background-head-container">
      <v-container>
        <v-row style="z-index: 50; position: relative">
          <v-col cols="11" md="8" class="title-container" style="margin: auto">
            <div class="header_text">
              <p class="principalText">{{ header[["firstText"]] }}</p>
              <p class="secondText">
                {{ header[["secondText"]] }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",

  data: () => ({}),

  computed: {
    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },
  },
};
</script>

<style scoped>
.header_text {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.header_text p {
  font-size: 3.2rem;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.principalText {
  font-weight: 500;
}
</style>
