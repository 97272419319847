const state = {
  items: [], //Items de view licenciaturas, maestrias, etc...
  question: "", //Ultima pregunta del cuestionario a desplegar
};
const getters = {
  getItems(state) {
    return state.items;
  },
  getQuestion(state) {
    return state.question;
  },
};
const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setQuestion(state, text) {
    state.question = text;
  },
};
const actions = {
  SET_ITEMS_FORM(context, items) {
    context.commit("setItems", items);
  },
  SET_QUESTION(context, text) {
    context.commit("setQuestion", text);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
