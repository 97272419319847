<template>
  <div class="networkSide">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          href="mailto:contacto@ahjende.com"
          target="_blank"
          style="rotate: -90deg; top: 150px; right: -63px"
          fixed
          text
        >
          <v-icon
            color="black"
            v-bind="attrs"
            v-on="on"
            style="margin-right: 5px; transform: rotate(90deg)"
          >
            mdi-email
          </v-icon>
          Contáctanos
        </v-btn>
      </template>
      Correo
    </v-tooltip>
    <br />
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          :href="`https://wa.me/5524109168?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          fixed
          style="top: 240px; right: 7px"
        >
          <v-icon color="green" v-bind="attrs" v-on="on"> mdi-whatsapp </v-icon>
        </v-btn>
      </template>
      Informes
    </v-tooltip>
    <!-- <v-btn
      icon
      target="_blank"
      fixed
      style="top: 240px; right: 7px"
      @click="showModal = !showModal"
    >
      <v-icon color="green"> mdi-whatsapp </v-icon>
    </v-btn> -->

    <!-- <div class="modalContact scale-up-center" v-if="showModal">
      <p
        class="font-weight-bold text-body-2 text-left blueColorText mb-3"
        style="line-height: normal"
      >
        Selecciona el CDE que deseas contactar
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/5559951685?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >Ecatepec</a
        >
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/5624363683?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >Naucalpan</a
        >
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/5585673027?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >Cuautitlán</a
        >
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/4428473531?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >Querétaro</a
        >
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/7712991696?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >Pachuca</a
        >
      </p>
      <p
        class="modalContact_link text-h6 font-weight-regular py-1 hvr-sweep-to-left"
      >
        <a
          :href="`https://wa.me/4442901569?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="showModal = !showModal"
          >San Luis Potosí</a
        >
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style scoped>
.modalContact {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgba(45, 40, 40, 0.65);
  padding: 1.5rem;
  position: absolute;
  top: 60px;
  right: 60px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.modalContact .modalContact_link a {
  color: #0092b7;
  text-decoration: none;
}
.modalContact .modalContact_link:hover a {
  color: white;
  transition-duration: 0.3s;
}

.hvr-sweep-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0092b7;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-left:hover,
.hvr-sweep-to-left:focus,
.hvr-sweep-to-left:active {
  color: black;
}
.hvr-sweep-to-left:hover:before,
.hvr-sweep-to-left:focus:before,
.hvr-sweep-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.networkSide {
  position: fixed;
  right: 0;
  top: 80px;
  z-index: 999;
  /* background: #0495b4; */
  background: white;
  color: white;
  border: 1px solid #0092b7;
  /* padding: 15pt 10pt; */
  /* border-bottom-left-radius: 10pt; */
  border-radius: 10pt;
  height: 205px;
  width: 50px;
}
</style>

<!-- <div class="networkContainer">
        <p style="transform: rotate(-90deg); padding: 12px">Contactános</p>
        <p>
          <v-tooltip left style="background-color: green">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon href="mailto:contacto@ahjende.com" target="_blank">
                <v-icon
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  style="rotate: -90deg"
                >
                  mdi-email
                </v-icon>
              </v-btn>
            </template>
            Correo
          </v-tooltip>
          <br />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :href="`https://wa.me/5564625048?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
                target="_blank"
              >
                <v-icon color="white" v-bind="attrs" v-on="on">
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </template>
            Informes
          </v-tooltip>
        </p>
      </div> -->
