<template>
  <v-container>
    <v-row>
      <v-col
        cols="11"
        sm="6"
        md="4"
        style="margin: auto"
        v-for="(cdeBtn, i) in cdesPlace"
        :key="i"
      >
        <v-btn
          text
          plain
          v-on:click="
            setData(
              cdeBtn[['map']],
              cdeBtn[['horario']],
              cdeBtn[['telfijo']],
              cdeBtn[['entrecalles']]
            )
          "
        >
          {{ cdeBtn[["name"]] }}<br />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CDESView",

  data: () => ({
    cdesPlace: [
      {
        name: "Ecatepec",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.621461159961!2d-99.0470744!3d19.6007116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f1a2c35931d1%3A0xba3e6a0eab745a32!2sInstituto%20AHJ-ENDE!5e0!3m2!1ses-419!2smx!4v1681539135501!5m2!1ses-419!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-7651-55-35 / 55-5770-5267 / 55-8406-7999",
        entrecalles: "Pensadores mexicanos y Benito Juárez Norte",
      },
      {
        name: "Cuautitlán",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.9933479543038!2d-99.1877379354111!3d19.670289186749734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f5944f94fe1f%3A0x127ced6e6eaad00a!2sAHJ%20ENDE%20CUAUTITLAN!5e0!3m2!1ses!2smx!4v1676301063011!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-6731-0978 / 56-2098-5195 / 55-8567-3027",
        entrecalles: "Av. 16 de Septiembre y 5 de Mayo",
      },
      {
        name: "Pachuca",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3746.299175084555!2d-98.74247467796359!3d20.12156254330637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109fb397279e9%3A0x183bd6983664cf7a!2sCalle%20Gral.%20Vicente%20Segura%20307-LT%2011%2C%20Centro%2C%2042060%20Pachuca%20de%20Soto%2C%20Hgo.!5e0!3m2!1ses!2smx!4v1676301116997!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "771-6098-348 / 56-1349-3915 / 771-2991-696",
        entrecalles: "Profa Laura Lugo y Gral. Daniel Cerecedo Estrada",
      },
      {
        name: "Naucalpan",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.7583718431592!2d-99.22829578541477!3d19.46598188686538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d20251b3489c8b%3A0x9158b5f05c82aa7d!2sAv.%20Primero%20de%20Mayo%2015%2C%20San%20Andres%20Atoto%2C%2053500%20Naucalpan%20de%20Ju%C3%A1rez%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1676301008399!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "55-7677-9111 / 771-3316-189 / 56-2436-3683",
        entrecalles: "C. Hormona y Blvd. Toluca",
      },
      {
        name: "Querétaro",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.0288381138903!2d-100.39837788539441!3d20.586879886239398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d3452a937e8a9d%3A0xbd3c0da159b98e11!2sAv.%20Zaragoza%20Pte.%2067%2C%20Centro%2C%2076000%20Santiago%20de%20Quer%C3%A9taro%2C%20Qro.!5e0!3m2!1ses!2smx!4v1676301161775!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "442-2404-101 / 55-3700-8180 / 442-8473-531",
        entrecalles: "C. Ignacio Perez y C. Nicolás Campa",
      },
      {
        name: "San Luis Potosí",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14781.24696487014!2d-100.99357100054405!3d22.152202519242078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842aa201fc90fffb%3A0x318299fedfad4ff6!2sCentro%20Historico%2C%2078000%20San%20Luis%2C%20S.L.P.!5e0!3m2!1ses!2smx!4v1676301259776!5m2!1ses!2smx",
        horario: "08:00 AM - 05:00 PM",
        telfijo: "444-1391-089 / 55-7946-6651 / 444-2901-569",
        entrecalles: "Av. Universidad y Vicente Guerrero",
      },
    ],
  }),

  mounted() {
    this.setCDEBtnWidth();
  },

  methods: {
    setData(_map, _horario, _telfijo, _entrecalles) {
      this.map = _map;
      this.horario = _horario;
      this.telfijo = _telfijo;
      this.entrecalles = _entrecalles;
    },
    setCDEBtnWidth() {
      var cdeBtns = window.document.getElementsByClassName("cdeBtn");
      var maxWidth = 0;
      for (let i = 0; i < cdeBtns.length; i++)
        if (cdeBtns[i].clientWidth > maxWidth)
          maxWidth = cdeBtns[i].clientWidth;
      for (let j = 0; j < cdeBtns.length; j++)
        cdeBtns[j].style.width = `${maxWidth}px`;
    },
  },

  computed: {
    map: {
      get() {
        return this.$store.getters["mapModule/isMapActive"];
      },
      set(newState) {
        if (this.$store.getters["mapModule/isMapActive"] !== newState) {
          this.$store.dispatch("mapModule/toogleMapState", newState);
        }
      },
    },
    horario: {
      get() {
        return this.$store.getters["horarioModule/isHorarioActive"];
      },
      set(newState) {
        if (this.$store.getters["horarioModule/isHorarioActive"] !== newState) {
          this.$store.dispatch("horarioModule/toogleHorarioState", newState);
        }
      },
    },
    telfijo: {
      get() {
        return this.$store.getters["telfijoModule/isTelfijoActive"];
      },
      set(newState) {
        if (this.$store.getters["telfijoModule/isTelfijoActive"] !== newState) {
          this.$store.dispatch("telfijoModule/toogleTelfijoState", newState);
        }
      },
    },
    entrecalles: {
      get() {
        return this.$store.getters["entrecallesModule/isEntrecallesActive"];
      },
      set(newState) {
        if (
          this.$store.getters["entrecallesModule/isEntrecallesActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "entrecallesModule/toogleEntrecallesState",
            newState
          );
        }
      },
    },
  },
};
</script>
