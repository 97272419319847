import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import BlogView from "../views/BlogView.vue";
import ContactView from "../views/ContactView.vue";
import CDESView from "../views/CDESView.vue";
import CareersView from "../views/CareersView.vue";
import HighschoolView from "../views/HighschoolView.vue";
import CoursesView from "../views/CoursesView.vue";
import WeAreView from "../views/WeAreView.vue";
// import ScholarsView from "../views/ScholarsView";
import ScholarsView from "../views/LicenciaturasView.vue";
import MaestriasView from "../views/MaestriasView.vue";
import BachilleratosView from "../views/BachilleratosView.vue";
import DiplomadosView from "../views/DiplomadosView.vue";
import ScholarshipsView from "../views/ScholarshipsView";
import VocacionalView from "../views/VocacionalView.vue";
import PrivacyView from "../views/PrivacyView";
import ResultsView from "../views/ResultsView";
import ContactFormView from "../views/ContactFormView";
import ContactResultsView from "../views/ContactResultsView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/contacto",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/cdes",
    name: "cdes",
    component: CDESView,
  },
  {
    path: "/carreras",
    name: "careers",
    component: CareersView,
  },
  {
    path: "/preparatoria",
    name: "highschool",
    component: HighschoolView,
  },
  {
    path: "/diplomados",
    name: "courses",
    component: CoursesView,
  },
  {
    path: "/somos",
    name: "we-are",
    component: WeAreView,
  },
  {
    path: "/oferta",
    name: "scholars",
    component: ScholarsView,
  },
  {
    path: "/oferta-maestrias",
    name: "maestrias",
    component: MaestriasView,
  },
  {
    path: "/oferta-bachilleratos",
    name: "bachilleratos",
    component: BachilleratosView,
  },
  {
    path: "/oferta-diplomados",
    name: "diplomados",
    component: DiplomadosView,
  },
  {
    path: "/becas",
    name: "scholarships",
    component: ScholarshipsView,
  },
  {
    path: "/vocacional",
    name: "vocacional",
    component: VocacionalView,
  },
  {
    path: "/aviso-de-privacidad",
    name: "privacy",
    component: PrivacyView,
  },
  {
    path: "/resultados-de-cuestionario",
    name: "results",
    component: ResultsView,
  },
  {
    path: "/contacto-de-alumnos",
    name: "contact form",
    component: ContactFormView,
  },
  {
    path: "/resultados-de-contacto",
    name: "contactResults",
    component: ContactResultsView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
