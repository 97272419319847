<template>
  <div>
    <v-app-bar app color="#000000" dark id="navContainer" style="z-index: 99">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-img
              src="https://lh3.googleusercontent.com/HzodaZ9MDOP58YA3znLNUiJMVjKeVi5wu-_ZBkRZT33WMuj-iqknlmIb7DFTSKNrXp0=w2400"
              class="logoImage navLogo"
              id="navLogo"
              style="cursor: pointer"
              @load="setFooterImgWidth"
              v-on:click="
                index
                  ? goTo('#index', { duration: 300 })
                  : goToRoute('/', '#index')
              "
            />
            <span class="navItems d-inline-flex d-md-none">
              <v-btn icon v-on:click="open = !open">
                <v-icon color="white"> mdi-menu </v-icon>
              </v-btn>
            </span>
            <span class="navItems d-none d-md-inline-flex pt-2">
              <v-btn
                href="https://plataforma.ahjende.com/"
                target="_blank"
                color="#fff"
                light
              >
                Plataforma
                <v-icon right> mdi-chevron-right </v-icon>
              </v-btn>
            </span>
            <!-- <span class="navItems d-none d-md-inline-flex pt-2">
              <v-btn
                text
                v-on:click="
                  vocacional
                    ? goTo('#vocacional', { duration: 300 })
                    : goToRoute('/vocacional', '#vocacional')
                "
              >
                Orientaci&oacute;n Vocacional
              </v-btn>
            </span> -->
            <!-- <span class="navItems d-none d-md-inline-flex pt-2">
              <v-btn
                text
                v-on:click="
                  blog
                    ? goTo('#blog', { duration: 300 })
                    : goToRoute('/blog', '#blog')
                "
                :color="$route.path === '/blog' ? '#00add1' : '#fff'"
              >
                Blog
              </v-btn>
            </span> -->
            <span
              class="navItems d-none d-md-inline-flex pt-2"
              style="float: right"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  text
                  v-on:click="
                    weAre
                      ? goTo('#we-are', { duration: 300 })
                      : goToRoute('/somos', '#we-are')
                  "
                  :color="
                    $route.path === '/somos' || hover ? '#00ACCF' : '#fff'
                  "
                >
                  ¿Qui&eacute;nes somos?
                </v-btn>
              </v-hover>
            </span>
            <span class="navItems d-none d-md-inline-flex pt-2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  text
                  v-on:click="
                    cdes
                      ? goTo('#cdes', { duration: 300 })
                      : goToRoute('/cdes', '#cdes')
                  "
                  :color="$route.path === '/cdes' || hover ? '#00ACCF' : '#fff'"
                >
                  CDE's
                </v-btn>
              </v-hover>
            </span>
            <span class="navItems d-none d-md-inline-flex pt-2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  text
                  v-on:click="
                    scholarships
                      ? goTo('#scholarships', { duration: 300 })
                      : goToRoute('/becas', '#scholarships')
                  "
                  :color="
                    $route.path === '/becas' || hover ? '#00ACCF' : '#fff'
                  "
                >
                  Becas
                </v-btn>
              </v-hover>
            </span>
            <span class="navItems d-none d-md-inline-flex pt-2">
              <v-menu offset-y open-on-hover v-model="showLinks">
                <template v-slot:activator="{ on }">
                  <v-btn text v-on="on" :color="showLinks ? '#00ACCF' : '#fff'">
                    oferta educativa
                    <v-icon right dark>
                      {{ showLinks ? "mdi-chevron-down" : "mdi-chevron-right" }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list class="list-menu">
                  <v-list-item
                    class="list-menu-item"
                    v-for="(item, index) in navbarItems"
                    :key="index"
                    @click="goToRoute(item.link, { duration: 300 })"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
var setNavLogoWidth;
var setFooterImgWidth;

export default {
  name: "App",

  data: () => ({
    items: [],
    showLinks: false,
    navbarItems: [
      { text: "Bachilleratos", link: "/oferta-bachilleratos" },
      { text: "Licenciaturas", link: "/oferta" },
      { text: "Diplomados", link: "/oferta-diplomados" },
      { text: "Maestrias", link: "/oferta-maestrias" },
    ],
  }),

  mounted() {
    setNavLogoWidth = setInterval(this.setNavLogoWidth, 100);
    setFooterImgWidth = setInterval(this.setFooterImgWidth, 100);
    console.log("ROUTE", this.$route.path);
  },

  beforeDestroy() {
    clearInterval(setNavLogoWidth);
    clearInterval(setFooterImgWidth);
  },

  methods: {
    setNavLogoWidth() {
      if (!this.privacy) {
        var navLogo = window.document.getElementById("navLogo");
        var screenWidth = window.innerWidth;
        navLogo.style.width = `${screenWidth > 960 ? 150 : 110}px`;
        navLogo.style.maxWidth = `${screenWidth > 960 ? 150 : 110}px`;
      }
    },

    setFooterImgWidth() {
      if (!this.privacy) {
        var navLogo = window.document.getElementById("navLogo");
        var footerLogo = window.document.getElementById("footerImage");
        footerLogo.style.width = `${navLogo.clientWidth}px`;
        footerLogo.style.maxWidth = `${navLogo.clientWidth}px`;
      }
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },
  computed: {
    blog: {
      get() {
        return this.$store.getters["blogModule/isBlogActive"];
      },
      set(newState) {
        if (this.$store.getters["blogModule/isBlogActive"] !== newState) {
          this.$store.dispatch("blogModule/toogleBlogState", newState);
        }
      },
    },

    cdes: {
      get() {
        return this.$store.getters["cdesModule/isCdesActive"];
      },
      set(newState) {
        if (this.$store.getters["cdesModule/isCdesActive"] !== newState) {
          this.$store.dispatch("cdesModule/toogleCdesState", newState);
        }
      },
    },

    index: {
      get() {
        return this.$store.getters["indexModule/isIndexActive"];
      },
      set(newState) {
        if (this.$store.getters["indexModule/isIndexActive"] !== newState) {
          this.$store.dispatch("indexModule/toogleIndexState", newState);
        }
      },
    },

    scholarships: {
      get() {
        return this.$store.getters["scholarshipsModule/isScholarshipsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarshipsModule/isScholarshipsActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "scholarshipsModule/toogleScholarshipsState",
            newState
          );
        }
      },
    },

    vocacional: {
      get() {
        return this.$store.getters["vocacionalModule/isVocacionalActive"];
      },
      set(newState) {
        if (
          this.$store.getters["vocacionalModule/isVocacionalActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "vocacionalModule/toogleVocacionalState",
            newState
          );
        }
      },
    },

    scholars: {
      get() {
        return this.$store.getters["scholarsModule/isScholarsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarsModule/isScholarsActive"] !== newState
        ) {
          this.$store.dispatch("scholarsModule/toogleScholarsState", newState);
        }
      },
    },

    open: {
      get() {
        return this.$store.getters["sideModule/isSideActive"];
      },
      set(newState) {
        if (this.$store.getters["sideModule/isSideActive"] !== newState) {
          this.$store.dispatch("sideModule/toogleSideState", newState);
        }
      },
    },

    weAre: {
      get() {
        return this.$store.getters["weAreModule/isWeAreActive"];
      },
      set(newState) {
        if (this.$store.getters["weAreModule/isWeAreActive"] !== newState) {
          this.$store.dispatch("weAreModule/toogleWeAreState", newState);
        }
      },
    },

    privacy: {
      get() {
        return this.$store.getters["privacyModule/isPrivacyActive"];
      },
      set(newState) {
        if (this.$store.getters["privacyModule/isPrivacyActive"] !== newState) {
          this.$store.dispatch("privacyModule/tooglePrivacyState", newState);
        }
      },
    },
  },
};
</script>

<style scoped>
.list-menu {
  background-color: rgba(45, 40, 40, 0.9);
}
.list-menu .list-menu-item {
  color: #0092b7 !important;
  background: linear-gradient(to left, #0092b7, #0092b7);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: all 0.4s 0s;
}
.list-menu .list-menu-item:hover {
  color: #fff !important;
  background-size: 100% 100%;
}
</style>
