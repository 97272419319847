<template>
  <div class="background-head" id="background-head">
    <div class="background-head-container">
      <v-container>
        <v-row style="z-index: 50; position: relative">
          <v-col
            cols="11"
            md="6"
            class="title-container mx-auto"
            style="margin: auto; max-height: 180px"
          >
            <div class="header_text">
              <p class="principalText">{{ header[["firstText"]] }}</p>
              <p class="secondaryText">
                {{ header[["secondText"]] }}
              </p>
            </div>
          </v-col>
          <v-col cols="11" md="6" style="margin: auto" class="py-0 mx-auto">
            <contact-form />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import contactForm from "../components/singleContactForm.vue";

export default {
  name: "FormHeaderComponent",
  components: {
    contactForm,
  },
  data: () => ({}),

  computed: {
    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },
  },
};
</script>

<style scoped>
.header_text p {
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.header_text .principalText {
  font-weight: 300;
}
.header_text .secondaryText {
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 900;
}

@media screen and (min-width: 780px) {
  .header_text .principalText {
    font-weight: 500;
    font-size: 3.2rem;
  }

  .header_text .secondaryText {
    font-size: 3.6rem;
  }
}
</style>
