<template>
  <div id="scholarships">
    <v-dialog v-model="modal" width="800">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="11" style="margin: auto">
              <p class="formTitle">¡Excelente Noticia!</p>
              <p
                style="
                  text-align: center;
                  border-bottom: 2px solid rgba(4, 149, 180, 0.3);
                  padding: 25pt 0;
                "
              >
                Tienes una beca disponible de {{ scholarship }}. ¡Contacta a tu
                asesor de carrera e inicia tu educaci&oacute;n con nosotros!
              </p>
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="descriptionText applyBtn"
              style="margin: auto"
            >
              <v-btn
                color="#0495b4"
                style="color: white"
                v-on:click="(closeForm = !closeForm), (modal = !modal)"
                :href="`https://wa.me/5564625048?text=¡Hola que tal! Realicé mi simulación de aprobación de beca. Mi nombre es ${name}, y la simulación me dijo que tengo una beca preaprobada de ${scholarship}. ¡Me gustaría tener contacto con mi asesor educativo!`"
                target="_blank"
              >
                ¡Solicitar beca!
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <header-component />
      <div
        id="descriptionContainer"
        style="padding: 35pt 0; background-color: #000"
      >
        <v-container>
          <v-row>
            <v-col cols="11" md="6" style="text-align: justify; margin: auto">
              <p style="color: #fff">
                En AHJ ENDE, uno de nuestras mayores objetivos es brindar
                educaci&oacute;n de calidad as&iacute; como brindarle acceso a
                esa educaci&oacute;n a la mayor cantidad de personas posibles en
                pro de mejores oportunidades y crecer como sociedad.
              </p>
              <p style="color: #fff">
                Sabemos que todos tenemos distintos retos y necesidades, es por
                ello que dise&ntilde;amos programas de becas especialmente para
                ti, aplica hoy mismo y sigue cumpliendo tus objetivos.
              </p>
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="descriptionText applyBtn"
              style="margin: auto"
            >
              <v-btn
                color="#0495b4"
                style="color: white"
                v-on:click="closeForm = !closeForm"
              >
                ¡Solicitar beca!
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container :class="{ closeForm: closeForm }" class="form">
        <v-row>
          <v-col cols="11" style="margin: auto">
            <p class="formTitle">
              Descubre cu&aacute;nto puedes obtener de beca
            </p>
          </v-col>
          <v-col cols="10" style="margin: auto; text-align: center">
            Recuerda responder este formulario con honestidad, puesto que
            tendr&aacute;s que probar aquello que menciones en la
            aplicaci&oacute;n a la beca.
          </v-col>
          <v-col cols="10" style="margin: auto">
            <v-text-field
              label="¿Cuál es tu nombre?"
              placeholder="Ingresa tu nombre"
              v-model="name"
              outlined
            />
          </v-col>
          <v-col
            cols="10"
            style="margin: auto"
            v-for="(header, idx) in Object.keys(form)"
            :key="idx"
          >
            <label>
              {{ question[idx][["placeholder"]] }}
            </label>
            <v-select
              label="Selecciona tu respuesta"
              v-model="form[[header]]"
              :rules="[rules.required]"
              item-text="question"
              item-value="weight"
              :items="question[idx][['values']]"
              clearable
              open-on-clear
              single-line
              outlined
            />
          </v-col>
          <v-col
            cols="11"
            class="descriptionText applyBtn"
            style="margin: auto"
          >
            <v-btn
              color="#0495b4"
              style="color: white"
              v-on:click="makePrediction()"
            >
              ¡Solicitar beca!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <div style="margin-top: 2rem">
        <v-container fluid>
          <v-row>
            <v-col
              cols="11"
              md="4"
              class="order-2 order-md-1 imgCol pa-0 pr-md-3"
              style="margin: auto"
            >
              <div class="overflow_image">
                <v-img :src="`/img/becasView/Becas2.jpg`" />
              </div>
            </v-col>
            <v-col
              cols="11"
              md="8"
              class="order-1 order-md-2 weAreFlexElement cardOverflow mt-5 mt-md-0"
              style="margin: auto; background-color: #0495b4"
            >
              <v-row>
                <v-col cols="11" md="8" style="margin: auto">
                  <p
                    class="pa-4 px-md-10 homeDescription"
                    style="text-align: justify; color: #fff"
                  >
                    En AHJ ENDE, uno de nuestros mayores objetivos es brindar
                    educación de calidad así cómo brindarle acceso a esa
                    educación a la mayor cantidad de personas posibles en pro de
                    mejores oportunidades y crecer como sociedad. <br />
                    <br />
                    Desde
                    <span class="font-weight-bold"> Bachillerato </span>
                    hasta
                    <span class="font-weight-bold"> educación superior </span>
                    en una variedad de áreas de estudio enfocado en los
                    negocios.<br /><br />
                    Nuestros programas académicos están diseñados para brindar a
                    nuestros estudiantes la formación y experiencia que
                    necesitan para alcanzar sus objetivos profesionales y
                    personales.<br /><br />
                    Estamos comprometidos por crear una comunidad acogedora para
                    todos nuestros estudiantes, ofreciendo oportunidades para
                    que participen en proyectos y actividades que impacten
                    positivamente en nuestra comunidad.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- <div class="instalationComponent">
        <div class="scholarshipsComponent" id="descriptionDecoration" />
        <v-container id="descriptionContainerText">
          <v-row>
            <v-col
              cols="11"
              md="6"
              class="order-2 order-md-1 imgCol"
              style="margin: auto"
            >
              <v-img :src="`/img/becasView/Becas2.jpg`" />
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="order-1 order-md-2 descriptionColContainer"
              style="margin: auto"
            >
              <v-container id="descriptionComponentContainer">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="homeDescription" style="color: #e6e6e6">
                      En AHJ ENDE, uno de nuestros mayores objetivos es brindar
                      educación de calidad así cómo brindarle acceso a esa
                      educación a la mayor cantidad de personas posibles en pro
                      de mejores oportunidades y crecer como sociedad.
                    </p>
                    <p class="homeDescription" style="color: #e6e6e6">
                      Desde
                      <span style="font-weight: bold"> Bachillerato </span>
                      hasta
                      <span style="font-weight: bold">
                        educación superior
                      </span>
                      en una variedad de áreas de estudio enfocado en los
                      negocios.
                    </p>
                    <p class="homeDescription" style="color: #e6e6e6">
                      Nuestros programas académicos están diseñados para brindar
                      a nuestros estudiantes la formación y experiencia que
                      necesitan para alcanzar sus objetivos profesionales y
                      personales.
                    </p>
                    <p class="homeDescription" style="color: #e6e6e6">
                      Estamos comprometidos por crear una comunidad acogedora
                      para todos nuestros estudiantes, ofreciendo oportunidades
                      para que participen en proyectos y actividades que
                      impacten positivamente en nuestra comunidad.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <contact-form />
      <chooseYourCDEComponent />
      <infoIndividualCDE />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import infoIndividualCDE from "@/components/infoIndividualCDE.vue";
import contactForm from "../components/newContactForm.vue";
import question from "../assets/js/question";
import rules from "../assets/js/rules";
import { required } from "vuelidate/lib/validators";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

var setLocationContainerPadding;
var setLocationDecorationHeight;

export default {
  name: "SchoclarshipsView",

  title() {
    return `AHJ ENDE Becas`;
  },

  data: () => ({
    loading: true,
    name: "",
    scholarship: "",

    closeForm: true,

    rules: rules,

    form: {
      civil: "",
      educationLevel: "",
      job: "",
      salary: "",
      economicDependency: "",
      familyMembers: "",
      mainSalary: "",
      assurance: "",
      home: "",
      transport: "",
      proposedStudy: "",
      career: "",
    },
    modal: false,
    question: question,
  }),

  validations: {
    name: { required },
    form: {
      civil: { required },
      educationLevel: { required },
      job: { required },
      salary: { required },
      economicDependency: { required },
      familyMembers: { required },
      mainSalary: { required },
      assurance: { required },
      home: { required },
      transport: { required },
      proposedStudy: { required },
      career: { required },
    },
  },

  components: {
    LottieAnimation,
    HeaderComponent,
    chooseYourCDEComponent,
    contactForm,
    infoIndividualCDE,
  },

  created() {
    this.getSearchElements();
    this.header[["firstText"]] = "Becas";
    this.header[["secondText"]] = "";
    this.headerPhoto = "/img/becasView/Becas1_recortada.jpg";
    // this.headerPhoto =
    //   "https://res.cloudinary.com/interprocsysmex/image/upload/v1685162807/ahjende/Becas/img1_becas_bjixru.jpg";
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.scholarships = true;
  },

  beforeDestroy() {
    clearInterval(setLocationContainerPadding);
    clearInterval(setLocationDecorationHeight);
  },

  destroyed() {
    this.scholarships = false;
  },

  methods: {
    getSearchElements() {
      var end = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      console.log(end);
      var rand = Math.floor(Math.random() * 3);
      console.log(rand);
      console.log(end[rand]);
      console.log(wrapperPos[rand]);
      axios
        .get(end[rand], {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[[wrapperPos[rand]]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            this.setDescriptionBackground();
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
            setLocationDecorationHeight = setInterval(
              this.setLocationDecorationHeight,
              100
            );
          }, 100);
        });
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("descriptionDecoration");
      var container = window.document.getElementById(
        "descriptionContainerText"
      );
      decoration.style.height = `${container.clientHeight}px`;
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "black";
      description.style.color = "#c2c2c2";
    },

    setLocationContainerPadding() {
      var cdeContainer = window.document.getElementById(
        "descriptionComponentContainer"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      cdeContainer.style.marginRight = `${paddingElement}px`;
      cdeContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    makePrediction() {
      if (!this.$v.$invalid) {
        var result = 0;
        Object.keys(this.form).forEach((element) => {
          result += this.form[[element]];
        });
        var model = {
          ...this.form,
          result: result,
        };
        console.log(model);
        let percentage = ["50%", "40%", "30%"];
        axios
          .post("/model/model-prediction", model)
          .then((response) => {
            let data = response.data;
            this.scholarship = percentage[parseInt(data[["Resultado"]]) - 1];
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.modal = true;
          });
      } else
        alert(
          "Debes de rellenar el formulario para saber tu beca. ¡Descubre lo que AHJ ENDE tiene para tí!"
        );
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    scholarships: {
      get() {
        return this.$store.getters["scholarshipsModule/isScholarshipsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarshipsModule/isScholarshipsActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "scholarshipsModule/toogleScholarshipsState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.ourPlantel_component {
  margin: 1.5rem 0;
}
@media screen and (min-width: 769px) {
  .ourPlantel_component {
    margin: 3.5rem 0;
  }

  .cardOverflow {
    height: 70vh;
  }

  .overflow_image {
    width: 130%;
  }
}
</style>
