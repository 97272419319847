<template>
  <div>
    <v-container fluid style="background-color: #000" class="pa-10">
      <v-row>
        <v-col
          cols="11"
          class="text text-center"
          style="margin: auto; color: #fff"
        >
          Actualmente contamos con 6 Centros de Desarrollo Empresarial
          distribuidos en la zona centro del pa&iacute;s, nuestra meta es llegar
          a 100 en los pr&oacute;ximos 15 a&ntilde;os, para estar cada vez al
          alcance de m&aacute;s personas con ganas de seguir superando sus metas
          y logros personales; nuestras ubicaciones actuales son:
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="separador" />
      </v-row>
    </v-container>
    <v-container class="pa-6">
      <v-row align="center">
        <v-col cols="11" md="3" class="text-left text-md-center">
          <p class="font-weight-bold text-body-1">
            Atenci&oacute;n al cliente e inscripciones
          </p>
          <p class="text-subtitle-1">{{ this.telfijo }}</p>
        </v-col>
        <v-col cols="11" md="3" class="text-left text-md-center">
          <p class="font-weight-bold text-body-1">Horario de atenci&oacute;n</p>
          <p class="text-subtitle-1">{{ this.horario }}</p>
        </v-col>
        <v-col cols="11" md="3" class="text-left text-md-center">
          <p class="font-weight-bold text-body-1">Referencia</p>
          <p class="text-subtitle-1">{{ this.entrecalles }}</p>
        </v-col>
        <v-col cols="11" md="3" class="text-left text-md-center">
          <p class="font-weight-bold text-body-1">Correo institucional</p>
          <p class="text-subtitle-1">contacto@ahjende.com</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "chooseYourCDEComponent",

  methods: {},

  computed: {
    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    map: {
      get() {
        return this.$store.getters["mapModule/isMapActive"];
      },
      set(newState) {
        if (this.$store.getters["mapModule/isMapActive"] !== newState) {
          this.$store.dispatch("mapModule/toogleMapState", newState);
        }
      },
    },
    horario: {
      get() {
        return this.$store.getters["horarioModule/isHorarioActive"];
      },
      set(newState) {
        if (this.$store.getters["horarioModule/isHorarioActive"] !== newState) {
          this.$store.dispatch("horarioModule/toogleHorarioState", newState);
        }
      },
    },
    telfijo: {
      get() {
        return this.$store.getters["telfijoModule/isTelfijoActive"];
      },
      set(newState) {
        if (this.$store.getters["telfijoModule/isTelfijoActive"] !== newState) {
          this.$store.dispatch("telfijoModule/toogleTelfijoState", newState);
        }
      },
    },
    entrecalles: {
      get() {
        return this.$store.getters["entrecallesModule/isEntrecallesActive"];
      },
      set(newState) {
        if (
          this.$store.getters["entrecallesModule/isEntrecallesActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "entrecallesModule/toogleEntrecallesState",
            newState
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.separador {
  background: #0092b7;
  padding: 0;
  height: 5px;
  margin: 15pt auto;
}
</style>
