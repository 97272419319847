<template>
  <v-app>
    <navbar-component v-if="!privacy" />
    <v-main id="appComponentStyle" :class="{ open: open }">
      <contactSideComponent />
      <side class="side" />
      <router-view class="view" />
    </v-main>
    <v-footer padless color="#000" v-if="!privacy">
      <v-container id="footerContainer" class="mt-md-10">
        <v-row class="footerFirstRow">
          <v-col cols="2" class="d-none d-md-inline-flex">
            <v-img
              src="https://lh3.googleusercontent.com/HzodaZ9MDOP58YA3znLNUiJMVjKeVi5wu-_ZBkRZT33WMuj-iqknlmIb7DFTSKNrXp0=w2400"
              class="logoImage"
              id="footerImage"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="8" class="d-none d-md-inline-flex" id="colContainer">
            <v-container class="colContainer">
              <v-row>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols font-weight-bold"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="openWindow()"
                >
                  Aviso de privacidad
                </v-col>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols font-weight-bold"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="
                    contact
                      ? goTo('#contact', { duration: 300 })
                      : goToRoute('/contacto', '#contact')
                  "
                >
                  Contacto
                </v-col>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols font-weight-bold"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="openURL('https://plataforma.ahjende.com/')"
                >
                  Ir a la plataforma
                </v-col>
                <v-col cols="6" lg="3" class="menuCols" id="menuCols">
                  <v-btn
                    icon
                    href="https://www.facebook.com/escueladenegociosydesarrolloempresarial"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-facebook </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    href="https://www.youtube.com/@ahj-endeescueladenegocios4351/featured"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-youtube </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    href="https://www.instagram.com/g.ende.ci/"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-instagram </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row class="footerFirstRow">
          <v-col
            cols="12"
            class="d-none d-md-inline-flex"
            id="colContainerFooter"
          >
            <v-container class="colContainer">
              <v-row align="end" justify="center">
                <v-col
                  cols="6"
                  lg="3"
                  justify="center"
                  align="center"
                  v-for="(item, i) in footerItems"
                  :key="i"
                  style="line-height: 90%"
                >
                  <p style="color: #2a99af" class="font-weight-bold text-h6">
                    {{ item.firstText }}
                  </p>
                  <p class="font-weight-black text-h5">{{ item.secondText }}</p>
                  <p class="blueColorText font-weight-black text-h6">
                    {{ item.thirdText }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row class="footerSecondRow">
          <v-col
            cols="10"
            md="6"
            class="colContainer"
            style="margin: auto; font-size: small"
          >
            Derechos Reservados ⓒ {{ new Date().getFullYear() }}, Escuela de
            Negocios y Desarrollo Empresarial, S. C.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import navbarComponent from "./components/navbar.vue";
import contactSideComponent from "./components/contactSideComponent.vue";
import side from "./components/sideComponent.vue";

export default {
  name: "App",

  data: () => ({
    footerItems: [
      {
        firstText: "Licenciatura en",
        secondText: "Negocios internacionales",
        thirdText: "RVOE: 2022P021048",
      },
      {
        firstText: "Licenciatura en",
        secondText: "Administración y Estrategia de Negocios",
        thirdText: "RVOE: 2022P021047",
      },
      {
        firstText: "Maestría en",
        secondText: "Administración de Empresas",
        thirdText: "RVOE: 2022P04373",
      },
      {
        firstText: "Bachillerato en",
        secondText: "Negocios",
        thirdText: "CCT: 17PBH0193X",
      },
      {
        firstText: "Licenciatura en",
        secondText: "Mercadotencia",
        thirdText: "RVOE: 2022P021046",
      },
      {
        firstText: "Bachillerato",
        secondText: "General",
        thirdText: "RVOE: EMS1522022",
      },
      {
        firstText: "Acuerdo de",
        secondText: "Incorporación",
        thirdText: "NO. 30UPAV024886P",
      },
    ],
  }),

  components: {
    navbarComponent,
    side,
    contactSideComponent,
  },

  created() {
    this.apiCheck();
  },

  methods: {
    apiCheck() {
      axios
        .get("/", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          console.log(data[["message"]]);
        });
    },

    openWindow() {
      this.goToRoute("/aviso-de-privacidad", "#privacy");
    },

    setFooterHeight() {
      var footerLogo = window.document.getElementById("colContainer");
      var menuCols = window.document.getElementById("menuCols");
      menuCols.style.height = `${footerLogo.clientHeight}px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    openURL(url) {
      window.open(url, "_blank");
    },
  },

  computed: {
    contact: {
      get() {
        return this.$store.getters["contactModule/isContactActive"];
      },
      set(newState) {
        if (this.$store.getters["contactModule/isContactActive"] !== newState) {
          this.$store.dispatch("contactModule/toogleContactState", newState);
        }
      },
    },

    open: {
      get() {
        return this.$store.getters["sideModule/isSideActive"];
      },
      set(newState) {
        if (this.$store.getters["sideModule/isSideActive"] !== newState) {
          this.$store.dispatch("sideModule/toogleSideState", newState);
        }
      },
    },

    privacy: {
      get() {
        return this.$store.getters["privacyModule/isPrivacyActive"];
      },
      set(newState) {
        if (this.$store.getters["privacyModule/isPrivacyActive"] !== newState) {
          this.$store.dispatch("privacyModule/tooglePrivacyState", newState);
        }
      },
    },
  },
};
</script>
<style>
@import "./assets/styles/styles.scss";
</style>
